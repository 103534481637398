import React from 'react'

function TextPCSW() {
  if (window.innerWidth > 992) {
    return (    
      <div className="mt-4">PUNJAB COMMISSION ON THE STATUS OF WOMEN</div>
    )
  }
  else {
    return (    
      <div>PUNJAB COMMISSION<br/>ON THE STATUS OF WOMEN</div>
    )
  }
 
}

export default TextPCSW
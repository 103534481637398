import React from 'react'

function ThemeText(props) {
    
    const {demography, health} = props.theme;
    if(props.theme == "demographics") {
        return (
            <div>
                Demographics is “the study of the size, territorial distribution, and composition of population, changes therein, 
                and the components of such changes.” Demographics are characteristics used to classify people for statistical purposes, 
                such as age, race or gender.<br/>
                Demographic trends and their relationship with the natural environment and social, economic and political change, 
                pose a challenge of great consequence. These realities govern, or at least set some broad limits upon, what is possible 
                for the economy and governments. Their analysis is of significance for a government to achieve and maintain priorities. 
                Thus, public policy makers are under pressure to understand dynamics of demographic change. Such trends provide a basis 
                for long term monitoring and tracing indicators of gender parity.<br/>
                Women make almost half of the national population. Though there has been significant improvement in overall economic 
                situation and other development indicators, in the last few decades, yet, there exists vast disparities among the 
                status of men and women in the society and reflected in many forms such as in the form of lower representation of women 
                at decision-making positions in the government and otherwise against their overall population.
            </div>
        )
    }
    else if(props.theme == "health") {
        return (
            <div>
                The World Health Organization (WHO) has defined health as ‘a state of complete physical, mental, and social well-being 
                and not merely the absence of disease or infirmity.’<br/>
                In recent years, the focus of developed and developing countries has shifted towards addressing women’s health issues. 
                The MDGs, signed by 189 states, showed a global commitment to improving maternal health, reducing child mortality and 
                empowering women as a whole. The decision to adopt gender sensitive health policies and budgets leads to a need for 
                high-quality, regular and disaggregated data. For this purpose, gendered targets or benchmarks are identified 
                which require the development of specific outcome measures or indicators.<br/>
                The indicators taken in this thematic area include but are not limited to infant and maternal mortality, fertility 
                rate and availability and use of health services. These indicators measure the effectiveness of the government’s 
                efforts to improve women’s health in the Punjab and reveal disparity in the provision of health services to support 
                policy change.
            </div>
        )
    }
    else if(props.theme == "education") {
        return (
            <div>
                Education is a basic human right that fosters economic growth and human development. International consensus on 
                education priorities accords an important place to achieving gender equality in the educational sphere. In the Global 
                Gender Gap Report 2015, the gender gap in educational attainment has decreased compared to 2006 and now stands at 95%. 
                However, Pakistan still ranks at 135 out of a total of 145 countries.<br/>
                Deprivation in education is one of the main causes of poverty and inequality. However, this is a two way relationship: 
                poverty and inequality are also among the main causes of educational deprivation. Educating girls produces many 
                additional socio-economic gains that benefit entire societies. These benefits include increased economic productivity, 
                higher family incomes, delayed marriages, reduced fertility rates, and improved health and survival rates for infants 
                and children.<br/>
                The Government of Pakistan is a signatory to many international treaties aimed at providing quality education, 
                especially for females. Hence, it is important to monitor progress with respect to achieving education related goals 
                that aim to enhance gender equity and economic empowerment.
            </div>
        )
    }
    else if(props.theme == "economic participation") {
        return (
            <div>
                Labour markets are one of the key mechanisms through which the disadvantageous groups can harbour the benefits of 
                growth. Access to Labour Markets and, more specifically, an opportunity to decent work are imperative for diminishing 
                the inequalities between men and women. Women’s participation in labour force not only serves to augment the household 
                income and welfare but also provides tremendous impetus to enchased participation in public affairs.<br/>
                Increasing opportunities for women will help improve economic performance and promote equity. If women are seen as 
                economically productive, more families will invest in female education and healthcare, and more women in the future 
                will be able to increase their productivity. However, women still face discrimination and persistent gender 
                inequalities. Once the drivers of, and constraints on, women’s economic opportunity are identified, policy makers can 
                address these to enhance their roles in the economy. It is also intended to prompt improvements in policy and programs 
                that will encourage women’s participation in the workplace and thus create more productive economies overall.
            </div>
        )
    }
    else if(props.theme == "justice") {
        return (
            <div>
                Legal rights are clearly rights which exist under the rules of legal systems or by virtue of decisions of suitably 
                authoritative bodies within them.<br/>
                The absence of the Rule of Law or the existence of a system that does not uphold the Rule of Law constitutes a 
                significant obstacle to the enjoyment of legal rights by citizens of a society. In countries with weak judicial 
                systems, both men and women regularly face difficulties in the enjoyment of legal rights and protections as provided 
                by the laws of that country. However, women often experience barriers in addition to those experienced by men. 
                The inability to access justice service providers significantly hampers women’s access to justice thereby preventing 
                them from enjoying rights and protections provided to them by laws. Even in cases where women manage to access 
                justice service providers and initiate a judicial process, biases and lack of gender sensitivity among judicial officers often 
                impede women’s chances of acquiring favorable decisions.<br/>
                Understanding and identifying areas where gender disparity is highest within the judicial system of the Punjab and 
                where it is that women face barriers in access to justice can help policy makers enact new policies to remove disparities.
            </div>
        )
    }
    else if(props.theme == "governance") {
        return (
            <div>
                Governance is “the exercise of economic, political and administrative authority to manage a country’s affairs at all 
                levels. It comprises mechanisms, processes, and institutions, through which citizens and groups articulate their 
                interests, exercise their legal rights, meet their obligations and mediate their differences.”<br/>
                An inclusive government strives towards equality and representation of women and men in decision making processes. 
                Voice is considered an important mechanism of accountability in a system of democratic disposition. It is widely 
                acknowledged that citizens who possess better capacity to express and exercise their views have the potential to 
                influence government priorities or the governance processes. Women need an effective ‘voice’ in order to convey their 
                views; and governments are more likely to respond to the needs and demands thus articulated. Women representation in 
                legislative bodies and government institutions provide them with a “voice” goading formulation of policies affecting 
                their wellbeing. Level of participation of women in government institutions, with respect to their population, thus, 
                is an important indicator to assess the parity index and viability of the governance model.
            </div>
        )
    }    
    else if(props.theme == "special initiative") {
        return (
            <div>
                Gender inequality and disparities foster discrimination, social injustice, sub-optimal economic progress and deficient 
                democracies. The discussion on six thematic areas shows that Gender inequality manifests itself in all aspects of 
                human life such as Health, Education, Law and Order, Governance, Representation, and civic and individual rights.<br/>
                In order to promote gender equality, it is essential that the government take “affirmative action” such as those 
                specified in the Punjab Women Empowerment Package (PWEP) 2012 and Punjab Women Empowerment Initiative (PWEI) 2014.<br/>
                Such initiatives aim to strengthen the social, economic and political position of women in Punjab. This section 
                provides implementation status of provisions contained both within the PWEP and PWEI as an effort to portray the 
                real picture of the impact of these initiatives for the government, policy makers, implementation bodies etc.
            </div>
        )
    }
}

export default ThemeText
import React from 'react'
import Overlay from './childrenComponents/Overlay'

function Indicator() {
  return (
    <div className='container-fluid indicators'>
        <Overlay />
        <div className='row'>
            <div className='col-4 bg-silver'>
                <div>
                    Years
                </div>
                <hr/>
                <div>
                    Gender
                </div>
            </div>
            <div className='col-8'>
                Indicators
            </div>
        </div>
    </div>
  )
}

export default Indicator
import React from 'react'
import { Link } from 'react-router-dom';
import HorMenu from './childrenComponents/HorMenu';
import NavbarLinks from './childrenComponents/NavbarLinks';
import Overlay from './childrenComponents/Overlay';

function Navbar() {
  const overlay = () => {
    const ele = document.getElementById('overlay');
    const btn = document.getElementById('btnOverlay');
    if (ele.classList.contains('open-overlay')) {
      ele.classList.remove('open-overlay');
      ele.classList.add('close-overlay');
      btn.classList.remove('btn-overlay-right');
      btn.classList.add('btn-overlay-left');
      document.getElementsByClassName('btn-slider')[0].style.backgroundColor = '#CCCCCC';
      document.getElementById('hormenu').style.maxHeight = '1000px';
    }
    else if (ele.classList.contains('close-overlay')) {
      ele.classList.remove('close-overlay');
      ele.classList.add('open-overlay');
      btn.classList.remove('btn-overlay-left');
      btn.classList.add('btn-overlay-right');
      document.getElementsByClassName('btn-slider')[0].style.backgroundColor = '#FFFFFF';
      document.getElementById('hormenu').style.maxHeight = '0px';
    }
    else {
      ele.classList.add('open-overlay');
      btn.classList.add('btn-overlay-right');
      document.getElementsByClassName('btn-slider')[0].style.backgroundColor = '#FFFFFF';
      document.getElementById('hormenu').style.maxHeight = '0px';
    }
  }

  return (
    <>
    <div className="container-fluid bg-silver g-0">
      <div className="row">
        <div className="col-12 text-center navbar-noboot" style={{ position: "relative", zIndex: "1" }}>
          <NavbarLinks />
        </div>
        <div className="col position-absolute">
          <div className='btn-slider' onClick={overlay}>
            <i className="fas fa-chevron-circle-right btn-overlay" id="btnOverlay"></i></div>
        </div>
      </div>
    </div>
    <div className='overlay-position'><Overlay /></div>
    <div style={{marginLeft:'50px'}}><HorMenu /></div>
    </>
  )
}

export default Navbar
import React from 'react'
import { Link } from 'react-router-dom';
import LogoGMIS from '../gallery/logos/8.png';

export default function Login() {
    const displayError = () => {
        document.getElementById('login-error').style.display = 'block';
    }
    return (
        <div className='login-form'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card border-0 shadow rounded-3 my-5">
                            <div className="card-body p-4 p-sm-5">
                                <h5 className="card-title text-center mb-5 fw-light fs-5">Staff Sign In</h5>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                    <label htmlFor="floatingInput">Email address</label>
                                    <div id='login-error' className='text-danger login-error'>Email not registered</div>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password" />
                                    <label htmlFor="floatingPassword">Password</label>
                                </div>

                                <div className="form-check mb-3">
                                    <input className="form-check-input rem-check" type="checkbox" value="" id="rememberPasswordCheck" />
                                    <label className="form-check-label" htmlFor="rememberPasswordCheck">
                                        Remember password
                                    </label>
                                </div>
                                <div className="d-grid">
                                    <button className="btn btn-primary btn-login text-uppercase fw-bold" onClick={() => displayError()}>Sign
                                        in</button>
                                </div>
                                <hr className="my-4" />
                                <div className="d-grid mb-2 login-logo">
                                    <div><Link to="/"><img src={LogoGMIS} alt="GoP Logo" className="img-fluid"></img></Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'

function Tile(props) {
  return (
    <div>
      <div className='tile-title'>{props.title}</div>
      <div className='tile' title={props.title}>
        {props.val}
      </div>
      <div className='tile-datatype'>{props.dataType}</div>
    </div>
  )
}

export default Tile
import React, { useState, useContext } from 'react';
import './App.css';
import './Styles.scss';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Theme from './components/Theme';
import b1 from './gallery/banners/1.jpg';
import b2 from './gallery/banners/2.jpg';
import b3 from './gallery/banners/3.jpg';
import b4 from './gallery/banners/4.jpg';
import b5 from './gallery/banners/5.jpg';
import b6 from './gallery/banners/6.jpg';
import b7 from './gallery/banners/7.jpg';
import i1 from './gallery/infographics/1.png';
import i2 from './gallery/infographics/2.png';
import i3 from './gallery/infographics/3.png';
import i4 from './gallery/infographics/4.png';
import i5 from './gallery/infographics/5.png';
import i6 from './gallery/infographics/6.png';
import i7 from './gallery/infographics/7.png';
import About from './components/About';
import Health from './components/Health';
import Visual from './components/Visual';
import { loginRequest } from "./authConfig";
import Test from './components/Test';
import PI from './components/PI';
import Report from './components/Report';
import Axios from 'axios';
import Barchart from './visuals/Barchart';
import Links from './components/Links';
import Contact from './components/Contact';
import Team from './components/Team';
import Indicator from './components/Indicator';
import Login from './components/Login';
import HorMenu from './components/childrenComponents/HorMenu';

export const UrlParamContext = React.createContext();
function App() {
  const [urlParam, setUrlParam] = useState(null);
  return (
    <div>
      <Header />
      <UrlParamContext.Provider value={{urlParam, setUrlParam}}>
        <Navbar />
      </UrlParamContext.Provider>
      <Routes>
        <Route exact path='/' element={<>
          <Theme bannerUrl={b1} bgTheme="bg-silver border-white box-silver" id="t1" btnid="btn1" bcid="bc1" icid="ic1" renderOverlay={true} bgInfog="bg-white" arrowColor="i-white" infogUrl={i1} theme="demographics" themeId="1" />
          <Theme bannerUrl={b6} bgTheme="bg-white border-silver box-white" id="t2" btnid="btn2" bcid="bc2" icid="ic2" renderOverlay={false} bgInfog="bg-silver" arrowColor="i-gold" infogUrl={i6} theme="governance" themeId="2" />
          <Theme bannerUrl={b2} bgTheme="bg-silver border-white box-silver" id="t3" btnid="btn3" bcid="bc3" icid="ic3" renderOverlay={false} bgInfog="bg-white" arrowColor="i-white" infogUrl={i2} theme="health" themeId="3" />
          <Theme bannerUrl={b3} bgTheme="bg-white border-silver box-white" id="t4" btnid="btn4" bcid="bc4" icid="ic4" renderOverlay={false} bgInfog="bg-silver" arrowColor="i-gold" infogUrl={i3} theme="education" themeId="4" />
          <Theme bannerUrl={b4} bgTheme="bg-silver border-white box-silver" id="t5" btnid="btn5" bcid="bc5" icid="ic5" renderOverlay={false} bgInfog="bg-white" arrowColor="i-white" infogUrl={i4} theme="economic participation" themeId="5" />
          <Theme bannerUrl={b5} bgTheme="bg-white border-silver box-white" id="t6" btnid="btn6" bcid="bc6" icid="ic6" renderOverlay={false} bgInfog="bg-silver" arrowColor="i-gold" infogUrl={i5} theme="justice" themeId="6" />
          <Theme bannerUrl={b7} bgTheme="bg-silver border-white box-silver" id="t7" btnid="btn7" bcid="bc7" icid="ic7" renderOverlay={false} bgInfog="bg-white" arrowColor="i-white" infogUrl={i7} theme="special initiative" themeId="7" />
        </>} />
        <Route path='report/:reportId' element={<Report pindId = {urlParam}/>} />
        <Route path='about' element={<About />} />
        <Route path='indicators' element={<Indicator />} />
        <Route path='links' element={<Links />} />
        <Route path='contact' element={<Contact />} />
        <Route path='team' element={<Team />} />
        <Route path='login' element={<Login />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

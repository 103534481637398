import Axios from 'axios';
import React, { Component, createContext } from 'react';
import parse from 'html-react-parser';

export class ThematicSections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pindHtml: '',
            stateUpdated: false,
            thSecData: null,
        }
    }

    componentDidMount() {
        Axios.get("https://www.gmis.gop.pk/api/uspgetthematicsections")
            .then(response => {
                response = response.data
                let subtheme = [], subtheme2 = [], pindSubtheme = []
                for (let i = 0; i < response.length; i++) {
                    if (response[i].theme_id == this.props.tid) {
                        pindSubtheme.push({
                            subthemeId: response[i].subtheme_id, subtheme: response[i].subtheme,
                            pindId: response[i].parent_indicator_id, pindName: response[i].parent_indicator
                        })
                        !subtheme2.includes(response[i].subtheme_id) && subtheme2.push(response[i].subtheme_id) &&
                            subtheme.push({ subthemeId: response[i].subtheme_id, subtheme: response[i].subtheme })
                    }
                }
                //console.log(subtheme) //make video of it how to get unique objects in array of objects
                
                const pindLinks = subthemeId => {
                    let pindLinks = '';
                    for (let i = 0; i < pindSubtheme.length; i++) {
                        if (pindSubtheme[i].subthemeId == subthemeId)
                        {
                            pindLinks += `<a href="/report/${pindSubtheme[i].pindId}" class='m-0'>${pindSubtheme[i].pindName}</a>`
                        }                        
                    }
                    return pindLinks;
                }
                
                let pindHtml = '';
                for (let i = 0; i < subtheme.length; i++) {

                    pindHtml += `
                    <div class="col col-md-6 col-xxl-4 mb-1">
                        <a class="btn subtheme" id="sub-${subtheme[i].subthemeId}"
                        data-bs-toggle="collapse" href="#stid-${subtheme[i].subthemeId}" role="button" aria-expanded="true" aria-controls="stid-${subtheme[i].subthemeId}">
                        <i class="fas fa-chevron-circle-right" id="i-${subtheme[i].subthemeId}"></i>
                        ${subtheme[i].subtheme}
                        </a>
                        <div class="collapse" id="stid-${subtheme[i].subthemeId}">
                            <div class="card card-body parent-indicator">
                            ${pindLinks(subtheme[i].subthemeId)}
                            </div>
                        </div>
                    </div>
                `
                }
                this.setState({ pindHtml: pindHtml, stateUpdated: true })
                //document.getElementById(`pindHtml-${this.props.tid}`).insertAdjacentHTML("afterbegin",this.state.pindHtml)
                if (this.state.stateUpdated) {
                    for (let i = 0; i < subtheme.length; i++) {
                        document.getElementById('sub-'+subtheme[i].subthemeId).addEventListener('click', ()=>changeDirection('sub-'+subtheme[i].subthemeId))
                    }
                    function changeDirection(subId) {
                        if (document.getElementById(subId).getAttribute('aria-expanded') == "true") {
                            document.getElementById(subId).firstElementChild.classList.remove('fa-chevron-circle-right');
                            document.getElementById(subId).firstElementChild.classList.add('fa-chevron-circle-down');
                        }
                        else {
                            document.getElementById(subId).firstElementChild.classList.remove('fa-chevron-circle-down');
                            document.getElementById(subId).firstElementChild.classList.add('fa-chevron-circle-right');
                        }
                    }
                }
                //console.log(pindSubtheme)
                //console.log(subtheme)
            })
            .catch(error => console.log(error))
    }

    render() {
        return (
            <div className="container-fluid bg-light mb-5" style={{ borderRadius: "10px" }}>
                <div className="row pt-2" id={`pindHtml-${this.props.tid}`}>
                    {parse(this.state.pindHtml)}
                </div>
            </div>
        )
    }
}

export default ThematicSections
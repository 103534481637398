import React, { useState, useEffect } from 'react';
import ThematicSections from './childrenComponents/ThematicSections';
import ThemeText from './childrenComponents/ThemeText';

function Theme(props) {
    const changeArrow = btnid => {
        if (document.getElementById(btnid).getAttribute('aria-expanded') == "true") {
            document.getElementById(btnid).firstElementChild.classList.remove('fa-chevron-circle-down');
            document.getElementById(btnid).firstElementChild.classList.add('fa-chevron-circle-up');
        }
        else {
            document.getElementById(btnid).firstElementChild.classList.remove('fa-chevron-circle-up');
            document.getElementById(btnid).firstElementChild.classList.add('fa-chevron-circle-down');
        }
    }
    const bannerStyle = {
        margin: '30px 0px 10px 30px',
        borderRadius: '10px',
        overflow: 'hidden',
    }
    const infogStyle = {
        margin: '30px auto 10px auto',
        borderRadius: '50%',
        overflow: 'hidden',
    }
    const setHeight = (icid, bcid) => {
        const divInfog = document.getElementById(icid);
        const divBanner = document.getElementById(bcid);
        divInfog.style.height = divBanner.offsetHeight.toString() + 'px';
        divInfog.style.width = divBanner.offsetHeight.toString() + 'px';
    }

    const scrollDown = () => {
        window.scrollBy(0, 400);
    }

    return (
        <div className={`container-fluid theme ${props.bgTheme}`}>
            <div className="row g-0" id={props.theme}>
                <div className="col-9 position-relative">
                    <div style={bannerStyle} className={props.bgInfog} id={props.bcid} onLoad={() => { setHeight(props.icid, props.bcid) }}>
                        <img src={props.bannerUrl} alt={props.theme} className="img-fluid" style={{ cursor: 'pointer' }} onClick={() => scrollDown()} data-bs-toggle="collapse" data-bs-target={`#${props.id}`} />
                        <div className="theme-name">{props.theme}</div>
                    </div>
                </div>
                <div className="col-3">
                    <div style={infogStyle} className={props.bgInfog} id={props.icid}>
                        <img src={props.infogUrl} alt={props.theme} className="img-fluid" style={{ cursor: 'pointer' }} onClick={() => scrollDown()} data-bs-toggle="collapse" data-bs-target={`#${props.id}`} />
                    </div>
                </div>
                <div className="col d-sm-none col-one">
                    <ThemeText theme={props.theme} />
                </div>
                <div className="col d-none d-sm-block d-xxl-none col-two">
                    <ThemeText theme={props.theme} />
                </div>
                <div className="col d-none d-xxl-block col-three">
                    <ThemeText theme={props.theme} />
                </div>
            </div>
            <div className="row">
                <div className='col text-center'>
                    <div data-bs-toggle="collapse" data-bs-target={`#${props.id}`} id={`${props.btnid}`} className="pt-3"
                        onClick={() => changeArrow(props.btnid)}>
                        <i className={`fas fa-chevron-circle-down i-size ${props.arrowColor}`}></i>
                    </div>
                    <div id={`${props.id}`} className="collapse">
                        {/* <a href="/data">subthemes</a> */}
                        <ThematicSections tid={props.themeId} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Theme
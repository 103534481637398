import React from 'react';
import team1 from '../gallery/team/1.jpeg';
import team2 from '../gallery/team/2.jpeg';
import team3 from '../gallery/team/3.jpeg';
import team4 from '../gallery/team/4.jpeg';
import team5 from '../gallery/team/5.jpeg';
import team6 from '../gallery/team/6.jpeg';
import team7 from '../gallery/team/7.jpeg';
import team8 from '../gallery/team/8.jpeg';
import team9 from '../gallery/team/9.jpeg';
import team10 from '../gallery/team/10.jpeg';
import team11 from '../gallery/team/11.jpeg';
import team12 from '../gallery/team/12.jpeg';
import team13 from '../gallery/team/13.jpeg';
import Overlay from './childrenComponents/Overlay';

export default function Team() {
    return (
        <div className="container-fluid" style={{minHeight:'500px',background:'white',padding:'20px'}}>
        <div className="row">
            <div className="col">
                <h5>GMIS TEAM</h5>
            </div>
        </div>
        <div className='row mt-5'>

            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Director Programs PCSW</h6>
                        <div className='img-box'><img src={team1} className='img-member' alt="Muhammad Waheed Iqbal" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Mr. Muhammad Waheed Iqbal</h6>
                        <p>Mr. Iqbal has expertise in project/program management, implementation, monitoring, evaluation and coordination particularly in Gender and development, Child Rights and related strategic and operational matters. He holds an MPhil degree in Sociology.</p>
                    </div>
                </div>
            </div>

            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Senior Research Analyst</h6>
                        <div className='img-box'><img src={team2} className='img-member' alt="Fizza Behzad Sethi" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Ms. Fizza Behzad Sethi</h6>
                        <p>Fizza is a Public Policy Analyst. Her research interest lies in policy processes, inclusive and sustainable development, social protection and gender. She holds an MS degree in Economics.</p>
                    </div>
                </div>
            </div>

            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Research Analyst</h6>
                        <div className='img-box'><img src={team3} className="img-member" alt="Saltanat Ansari" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Ms. Saltanat Ansari</h6>
                        <p>Sultanat is a Research professional passionate about public policy, development and administration. She holds a degree in International Relations.</p>
                    </div>
                </div>
            </div>

            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Research Analyst</h6>
                        <div className='img-box'><img src={team4} className="img-member" alt="Maria Rizwan Khan" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Ms. Maria Rizwan Khan</h6>
                        <p>Maria is an Advocate by profession. She holds a deep interest in policy-making, gender analysis, and capacity building.</p>
                    </div>
                </div>
            </div>

            <div className='col-12 col-xl-6 border mt-3' title='For web/app queries or suggestions:
            email: adeelrauf@gmail.com
            whatsapp: +923456789765'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>IT Manager GMIS</h6>
                        <div className='img-box'><img src={team5} className="img-member" alt="Adeel Rauf" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Mr. Adeel Rauf</h6>
                        <p>Adeel is an IT professional with advanced skills in system analysis and design, database design and development, frontend/backend development, data visualization, and also ui/ux development. He has work experience in figma, .net core, Laravel, ReactJs, Angular x, React Native, Power BI, and ChartJs. He holds a master's degree in Computer Science.</p>
                    </div>
                </div>
            </div>

            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Statistician/Graphic Designer GMIS</h6>
                        <div className='img-box'><img src={team6} className="img-member" alt="Syed Khalid Shah" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Mr. Syed Khalid Shah</h6>
                        <p>Khalid is a Statistician with advanced abilities in graphic design. He has a profound understanding of datasets and quantitative analysis skills. He holds a master's degree in Statistics and Visual Graphics.</p>
                    </div>
                </div>
            </div>

            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Divisional Coordinator Rawalpindi</h6>
                        <div className='img-box'><img src={team7} className="img-member" alt="Qaiser Mahmood" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Mr. Qaiser Mahmood</h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Divisional Coordinator Sargodha</h6>
                        <div className='img-box'><img src={team8} className="img-member" alt="Talawat Hussain" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Mr. Talawat Hussain</h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Divisional Coordinator Sahiwal</h6>
                        <div className='img-box'><img src={team9} className="img-member" alt="Shumaila Iqbal" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Ms. Shumaila Iqbal</h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Divisional Coordinator D.G. Khan</h6>
                        <div className='img-box'><img src={team10} className="img-member" alt="Muhammad Ali" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Mr. Muhammad Ali</h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Data Processor</h6>
                        <div className='img-box'><img src={team11} className="img-member" alt="Naeem Hussain" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Mr. Naeem Hussain</h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Data Processor</h6>
                        <div className='img-box'><img src={team12} className="img-member" alt="Abdul Samad" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Mr. Abdul Samad</h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className='col-12 col-xl-6 border mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='designation-box text-uppercase'>Data Processor</h6>
                        <div className='img-box'><img src={team13} className="img-member" alt="Abdul Qayyum" /></div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <h6 className='member-name'>Mr. Abdul Qayyum</h6>
                        <p></p>
                    </div>
                </div>
            </div>

        </div>
    </div>
      )
}

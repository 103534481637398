import React from 'react';
import {subIndiCol} from '../Colours';
import { YearList, dataType, xAxis, SubindicatorList, DistrictList, topPieData, subindiData, districtData, yearWiseData } from '../../components/DataReset';
import Piechart from '../../visuals/Piechart';
import Tile from '../../visuals/Tile';
import GroupBar from '../../visuals/GroupBar';

function F1(props) {
    const distList = DistrictList(props.data)
    const displayDist = distList.length > 0? 'block':'none';
    const subindiM = subindiData(props.data)[0]
    const displaySM = subindiM? 'block':'none';
    const subindiF = subindiData(props.data)[1]
    const displaySF = subindiF? 'block':'none';
    const subindiT = subindiData(props.data)[2]
    const displayST = subindiT? 'block':'none';
    const subindiV = subindiData(props.data)[3]
    const displaySV = subindiV? 'block':'none';

    let displayTile;
    displayTile = topPieData(props.data)[0].length > 1?displayTile = false:displayTile = true;

    let pieAspectRatio;
    pieAspectRatio = window.screen.width > 991? pieAspectRatio=1.8:pieAspectRatio=1.2;
    
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col col-lg-6'>
                    <div className='visual-head mt-1 text-center' data-bs-toggle="collapse" data-bs-target="#v1" aria-expanded="true" aria-controls="v1">
                        Selected Year
                    </div>
                    <div className="collapse show" id="v1">
                        <div className="card card-body visual">
                            {(displayTile && <Tile val={topPieData(props.data)[2][0]} title={props.data[0].parent_indicator} dataType="Number"/>)
                            || (!displayTile && <Piechart lbl={topPieData(props.data)[0]} colours={topPieData(props.data)[1]} data={topPieData(props.data)[2]} title={props.data[0].parent_indicator} pieAR={pieAspectRatio}/>)}
                        </div>
                    </div>
                </div>
                <div className='col col-lg-6'>
                    <div className='visual-head mt-1 text-center' data-bs-toggle="collapse" data-bs-target="#v2" aria-expanded="true" aria-controls="v2">
                        All Years
                    </div>
                    <div className="collapse show" id="v2">
                        <div className="card card-body visual">
                            <GroupBar lbl={YearList(props.alldata)} data={yearWiseData(props.alldata)} title={props.alldata[0].parent_indicator} xAxis={xAxis.pb} yAxis={dataType.n} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row justify-content-center' style={{display:displayDist}}>
                <div className='col'>
                    <div className='visual-head mt-1 text-center' data-bs-toggle="collapse" data-bs-target="#v2" aria-expanded="true" aria-controls="v2">
                        District Wise
                    </div>
                    <div className="collapse show bg-white" id="v2">
                        <div className="card card-body visual mx-auto">
                        <GroupBar lbl={distList} data={districtData(props.data)} title={props.data[0].parent_indicator} xAxis={xAxis.d} yAxis={dataType.n} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row justify-content-center'>
                <div className='col col-lg-6' style={{display:displaySM}}>
                    <div className='visual-head mt-1 text-center' data-bs-toggle="collapse" data-bs-target="#v1" aria-expanded="true" aria-controls="v1">
                        Male
                    </div>
                    <div className="card card-body visual">
                        <Piechart lbl={SubindicatorList(props.data)} colours={subIndiCol} data={subindiData(props.data)[0]} title={props.data[0].parent_indicator} pieAR={pieAspectRatio} />
                    </div>
                </div>
                <div className='col col-lg-6' style={{display:displaySF}}>
                    <div className='visual-head mt-1 text-center' data-bs-toggle="collapse" data-bs-target="#v1" aria-expanded="true" aria-controls="v1">
                        Female
                    </div>
                    <div className="card card-body visual">
                        <Piechart lbl={SubindicatorList(props.data)} colours={subIndiCol} data={subindiData(props.data)[1]} title={props.data[0].parent_indicator} pieAR={pieAspectRatio} />
                    </div>
                </div>
                <div className='col col-lg-6' style={{display:displayST}}>
                    <div className='visual-head mt-1 text-center' data-bs-toggle="collapse" data-bs-target="#v1" aria-expanded="true" aria-controls="v1">
                        Transgender
                    </div>
                    <div className="card card-body visual">
                        <Piechart lbl={SubindicatorList(props.data)} colours={subIndiCol} data={subindiData(props.data)[2]} title={props.data[0].parent_indicator} pieAR={pieAspectRatio} />
                    </div>
                </div>
                <div className='col col-lg-6' style={{display:displaySV}}>
                    <div className='visual-head mt-1 text-center' data-bs-toggle="collapse" data-bs-target="#v1" aria-expanded="true" aria-controls="v1">
                        Value
                    </div>
                    <div className="card card-body visual">
                        <Piechart lbl={SubindicatorList(props.data)} colours={subIndiCol} data={subindiData(props.data)[3]} title={props.data[0].parent_indicator} pieAR={pieAspectRatio} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default F1
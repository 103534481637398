import React from 'react'
import Overlay from './childrenComponents/Overlay'

function Contact() {
    return (
        <div className="container-fluid" style={{ minHeight: '500px', background: 'white', padding: '20px' }}>
            <div className="row">
                <div className="col">
                    <h5>Contact Us</h5>
                    <p>Punjab Commission on the Status of Women - PCSW<br />88-Shadman II<br />Lahore - Pakistan</p>
                    <hr />
                    <p>Landline: +92(0)4299268000-4</p>
                    <hr />
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3400.6281716652084!2d74.33437387553889!3d31.53437087420759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904eb61d39bbf%3A0x2025d2866934c59d!2sPCSW!5e0!3m2!1sen!2s!4v1670868371767!5m2!1sen!2s" width="600" height="450" loading="lazy"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Contact
import React, { useEffect, useState } from 'react';
import { YearList, SubindicatorList, SourceList } from '../DataReset';

function Filter(props) {
    const [yearChange, setYearChange] = useState('all');
    //const [subindiChange, setSubindiChange] = useState('all');
    const [sourceChange, setSourceChange] = useState('all');

    //const [initialData, setInitialData] = useState(props.data);
    const initialData = props.data;

    const yearList = YearList(initialData);
    //const subindiList = SubindicatorList(initialData);
    const sourceList = SourceList(initialData);

    let filteredData = [];

    const yearFilter = e => {
        setYearChange(e.value)
        if (e.value != 'all' && sourceChange != 'all') {
            for (let i = 0; i < initialData.length; i++) {
                if (initialData[i].data_year == e.value && initialData[i].source_name == sourceChange) {
                    filteredData.push(initialData[i])
                }
            }
        }
        else if (e.value != 'all' && sourceChange == 'all') {
            for (let i = 0; i < initialData.length; i++) {
                if (initialData[i].data_year == e.value) {
                    filteredData.push(initialData[i])
                }
            }
        }
        else if (e.value == 'all' && sourceChange != 'all') {
            for (let i = 0; i < initialData.length; i++) {
                if (initialData[i].source_name == sourceChange) {
                    filteredData.push(initialData[i])
                }
            }
        }
        else {
            filteredData = initialData;
        }
        return filteredData;
    }

    const sourceFilter = e => {
        setSourceChange(e.value)
        if (e.value != 'all' && yearChange != 'all') {
            for (let i = 0; i < initialData.length; i++) {
                if (initialData[i].data_year == yearChange && initialData[i].source_name == e.value) {
                    filteredData.push(initialData[i])
                }
            }
        }
        else if (e.value != 'all' && yearChange == 'all') {
            for (let i = 0; i < initialData.length; i++) {
                if (initialData[i].source_name == e.value) {
                    filteredData.push(initialData[i])
                }
            }
        }
        else if (e.value == 'all' && yearChange != 'all') {
            for (let i = 0; i < initialData.length; i++) {
                if (initialData[i].data_year == yearChange) {
                    filteredData.push(initialData[i])
                }
            }
        }
        else {
            filteredData = initialData;
        }
        return filteredData;
    }

    return (
        <div className='row'>
            <div className='col-6 border box-glow'>
                {/* <label className="label-year"><input type="radio" id='rad-year-all' name="rad-year" onChange={event=>props.onChange(yearFilter(event.target))} value='all' defaultChecked={true}/> All Years</label> */}
                {yearList.map((x, i) => (
                    <label className='label-year' key={i}>
                        <input type="radio" id={'rad-year-' + i} name="rad-year" onChange={event => props.onChange(yearFilter(event.target))} value={x} defaultChecked={i == yearList.length - 1 ? true : false} /> {x}
                    </label>
                ))}
            </div>

            {/* <div className='col-4 border'>
            <label className="label-subindi"><input type="radio" id='rad-subindi-all' name="rad-subindi" onChange={event=>props.onChange(subindiFilter(event.target))} value='all' defaultChecked/> All Subindicators</label>
                {subindiList.map((x,i)=>(
                    <label className='label-subindi' key={i}>
                        <input type="radio" id={'rad-subindi-'+i} name="rad-subindi" onChange={event=>props.onChange(subindiFilter(event.target))} value={x} /> {x}
                    </label>
                ))}
            </div> */}

            <div className='col-6 border box-glow'>
                <label className="label-source"><input type="radio" id='rad-source-all' name="rad-source" onChange={event => props.onChange(sourceFilter(event.target))} value='all' defaultChecked /> All Sources</label>
                {sourceList.map((x, i) => (
                    <label className='label-source' key={i}>
                        <input type="radio" id={'rad-source-' + i} name="rad-source" onChange={event => props.onChange(sourceFilter(event.target))} value={x} /> {x}
                    </label>
                ))}
            </div>
        </div>
    )
}

export default Filter
import React from 'react'
import Overlay from './childrenComponents/Overlay';

function Links() {
    return (
        <div className="container-fluid" style={{ minHeight: '500px', background: 'white', padding: '20px' }}>
            <div className="row">
                <div className="col-12">
                    <h4 className='text-dark text-center'>Useful Links</h4>
                </div>
            </div>
            <div className="row pt-5">
                <div className='col-4'>
                    <h5 className='text-dark'>Provincial</h5>
                    <ul>
                        <li><a href="https://bos.gop.pk/" target="_blank">Bureau of Statistics Punjab</a></li>
                        <li><a href="https://pnd.punjab.gov.pk/" target="_blank">PND</a></li>
                        <li><a href="punjabpolice.gov.pk" target="_blank">Punjab Police</a></li>
                        <li><a href="https://newsite.phc.org.pk/#/home" target="_blank">Punjab Healthcare Commission</a></li>
                        <li><a href="https://wdd.punjab.gov.pk/" target="_blank">PWDD</a></li>
                        <li><a href="https://pcsw.punjab.gov.pk/" target="_blank">PCSW</a></li>
                    </ul>
                </div>
                <div className='col-4'>
                    <h5 className='text-dark'>National</h5>
                    <ul>
                        <li><a href="https://na.gov.pk/" target="_blank">National Assembly</a></li>
                        <li><a href="https://ncsw.gov.pk/" target="_blank">NCSW</a></li>
                        <li><a href="https://www.pbs.gov.pk/" target="_blank">Pakistan Bureau of Statistics</a></li>
                    </ul>
                </div>
                <div className='col-4'>
                    <h5 className='text-dark'>International</h5>
                    <ul>
                        <li><a href="https://www.jica.go.jp/english/index.html" target="_blank">JICA</a></li>
                        <li><a href="https://www.worldbank.org/en/home" target="_blank">World Bank</a></li>
                        <li><a href="https://www.imf.org/en/home" target="_blank">IMF</a></li>
                        <li><a href="https://pildat.org/" target="_blank">PILDAT</a></li>
                        <li><a href="https://fafen.org/careers/" target="_blank">FAFEN</a></li>
                        <li><a href="https://www.un.org/womenwatch/daw/cedaw/" target="_blank">CEDAW</a></li>
                        <li><a href="http://aserpakistan.org/index.php" target="_blank">ASER</a></li>
                        <li><a href="https://www.unicef.org/pakistan/" target="_blank">UNICEF</a></li>
                        <li><a href="http://www.pk.undp.org/" target="_blank">UNDP</a></li>
                        <li><a href="https://www.unwomen.org/en" target="_blank">UNWomen</a></li>
                        <li><a href="https://gallup.com.pk/" target="_blank">GALLUP</a></li>
                        <li><a href="http://www.ilo.org/global/about-the-ilo/lang--en/index.htm" target="_blank">ILO</a></li>
                        <li><a href="https://sdpi.org/" target="_blank">SDPI</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Links
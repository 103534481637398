import React, { useContext } from 'react'
import i1 from '../../gallery/infographics/1.png';
import i2 from '../../gallery/infographics/6.png';
import i3 from '../../gallery/infographics/2.png';
import i4 from '../../gallery/infographics/3.png';
import i5 from '../../gallery/infographics/4.png';
import i6 from '../../gallery/infographics/5.png';
import i7 from '../../gallery/infographics/7.png';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { UrlParamContext } from '../../App';

function HorMenu() {
    const [menuData, setMenuData] = useState(null);
    const { urlParam, setUrlParam } = useContext(UrlParamContext);
    const changeParam = val => {
        setUrlParam(val);
    }
    let ThPi = []; //In development environment, components render twice, it will cause ThPi to fill twice 
    //and hence menuData. That will result overlay menu double in length.
    useEffect(() => {
        Axios.get("https://www.gmis.gop.pk/api/uspgetthematicsections")
            .then(response => {
                response = response.data
                for (let j = 1; j <= 7; j++) {
                    let subtheme = []; let subtheme2 = []; let pindSubtheme = []
                    for (let i = 0; i < response.length; i++) {
                        if (response[i].theme_id == j) {
                            pindSubtheme.push({
                                subthemeId: response[i].subtheme_id, subtheme: response[i].subtheme,
                                pindId: response[i].parent_indicator_id, pindName: response[i].parent_indicator
                            })
                            !subtheme2.includes(response[i].subtheme_id) && subtheme2.push(response[i].subtheme_id) &&
                                subtheme.push({ subthemeId: response[i].subtheme_id, subtheme: response[i].subtheme })
                        }
                    }
                    ThPi.push(pindSubtheme);
                }
                setMenuData(ThPi);
            })
            return() => {
                setMenuData(null)
            }
    }, []);

    const sScroll = theme => {
        //const pos = document.getElementById(theme).offsetTop;
        //window.scrollTo(0, pos);
    }

    const onHover = (id, num) => {
        const eleInfo = document.getElementById(id).getBoundingClientRect();
        const eleNav = document.getElementById('hor-nav-container-'+num);
        //(window.innerWidth < 786) && (eleNav.style.top = (eleInfo.top + window.pageYOffset + 70) + 'px');
        //(window.innerWidth >= 786) && (eleNav.style.top = (eleInfo.top + window.pageYOffset + 100) + 'px');
        eleNav.style.top = (eleInfo.top + window.pageYOffset + 100) + 'px';
    }

    let arrSubId = []; let arrSubTh = [];
    for (let i = 0; i < 7; i++) {
        menuData && !arrSubId.includes(menuData[0][i].subthemeId) && arrSubId.push(menuData[0][i].subthemeId) && arrSubTh.push(menuData[0][i].subtheme)
    }
    //const applyTransition = (i, j) => document.getElementById('link-container-'+i+'-'+j).style.transition = 'height 0.5s';
    const linkExpand = (i, j) => {
        //document.getElementById('link-container-'+i+'-'+j).style.transition = 'height 0.5s';
        if (document.getElementById('hor-link-container-' + i + '-' + j).style.maxHeight == '1500px') {
            document.getElementById('hor-link-container-' + i + '-' + j).style.maxHeight = '0px'
        }
        else {
            document.getElementById('hor-link-container-' + i + '-' + j).style.maxHeight = '1500px'
        }
    }
    if (menuData) {
        let arrComp = [];
        const imgs = [i1, i2, i3, i4, i5, i6, i7];
        const themeName = n => {
            if (n == 0) return 'Demographics';
            if (n == 1) return 'Governance';
            if (n == 2) return 'Health';
            if (n == 3) return 'Education';
            if (n == 4) return 'Economic Participation';
            if (n == 5) return 'Justice';
            if (n == 6) return 'Special Initiative';
        }
        return (
            <div className='container'>{/*Horizontal menu is hidden because some testing require to fix its display on navigation*/}
                <div className='row hor-col-overlay' id="hormenu">
                    <div className="col">
                        {
                            menuData.map((x, i) => (
                                <div className="hor-infogOverlay me-2 bg-white" id={themeName(i)} title={themeName(i)} style={{ backgroundImage: 'url(' + imgs[i] + ')', }} key={'theme-id' + i} onMouseEnter={()=>onHover(themeName(i), i)}>
                                    <div className='hor-theme-name'>{themeName(i)}</div>
                                    <div className='hor-nav-container' id={'hor-nav-container-'+i}>
                                        {
                                            x.map((y, j) => (
                                                (!arrComp.includes(y.subtheme)) && arrComp.push(y.subtheme) && (
                                                    <div key={'subtheme' + j}>
                                                        <div className='hor-nav-item' title={y.subtheme} onClick={() => { linkExpand(i, j) }}>
                                                            <div className='hor-nav-text'>{y.subtheme}</div><i className="fa fa-caret-down nav-arrow"></i>
                                                        </div>
                                                        <div className='hor-link-container' id={'hor-link-container-' + i + '-' + j}>
                                                            {
                                                                x.map((z, k) => (
                                                                    (z.subtheme == y.subtheme) && (
                                                                        <Link to={"/report/" + z.pindId} title={z.pindName} className='nav-link' key={'link-id-' + k} onClick={() => changeParam(z.pindId)}>
                                                                            {z.pindName}
                                                                        </Link>
                                                                    )))
                                                            }
                                                        </div>
                                                    </div>
                                                )))}
                                    </div>
                                </div>
                            ))}

                    </div>
                </div>
            </div>
        )
    }
}

export default HorMenu
import React from 'react'
import { Link } from 'react-router-dom';

function NavbarLinks() {
    if (window.innerWidth > 992) {
        return (
            <>
                <Link to="/" className='navbar-link'>Home</Link>|
                {/* <Link to="about" className='navbar-link'>About us</Link>| */}
                {/* <Link to="indicators" className='navbar-link'>Indicators</Link>| */}
                <Link to="links" className='navbar-link'>Useful links</Link>|
                {/* <Link to="team" className='navbar-link'>Our Team</Link>| */}
                <Link to="contact" className='navbar-link'>Contact us</Link>
            </>
          )
      }
      else {
        return (
            <>
                <Link to="/">Home</Link>|
                {/* <Link to="about">About us</Link>| */}
                {/* <Link to="indicators">Indicators</Link><br/> */}
                <Link to="links">Useful links</Link>|
                {/* <Link to="team">Our Team</Link>| */}
                <Link to="contact">Contact us</Link>
            </>
          )
      }
}

export default NavbarLinks
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import F1 from './frames/F1';
import F2 from './frames/F2';
import Filter from './childrenComponents/Filter';
import { useParams } from 'react-router-dom';
import { YearList } from './DataReset';

const Report = (props) => {
  const [mainData, setMainData] = useState(null);
  const [resData, setResData] = useState(null);
  const pind_id = useParams().reportId;
  
  useEffect(()=>{
    Axios.get(`https://www.gmis.gop.pk/api/uspmains/${pind_id}`)
          .then(response => {
            //setMainData(response.data);
            setResData(response.data);
              const lastYear = YearList(response.data).reverse()[0];
              let lastYearData = [];
              for (let i = 0; i < response.data.length; i++) {
                if(response.data[i].data_year == lastYear) {
                  lastYearData.push(response.data[i])
              }
                }
              setMainData(lastYearData);
          });          
        },[props.pindId]);

        if (mainData && !mainData.length > 0) {
          return (<div className='report'><Filter data={resData} onChange={value => setMainData(value)}/><h3 className='text-light text-center mt-5'>N/A</h3></div>)
        }
        else if (mainData) {
          if (mainData[0].data_unit == 'Number') {
            return (<div className='report'><Filter data={resData} onChange={value => setMainData(value)}/><F1 data={mainData} alldata={resData}/></div>)
          }
          else if (mainData[0].data_unit != 'Number') {
            return (<div className='report'><Filter data={resData} onChange={value => setMainData(value)}/><F2 data={mainData} alldata={resData}/></div>)
          }
        }
}

export default Report
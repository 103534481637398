import React from 'react'
import {useLocation} from "react-router-dom";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

function Health() {
    const search = useLocation().search;
    //const search = props.location.search;
    const pind_id = new URLSearchParams(search).get('id');
    let url, indi_name;
    const ifr = [
        {
            id: 332,
            name: 'Healthcare Budget (in Billions)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYmU3NmMzZDctNDdmYS00NzczLWIyMGUtZGJhNDcyYjIyNjE2IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 339,
            name: 'Service Centers',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNGI0OTRhY2ItMjcxOS00MTU0LWI3N2MtZjNiYWE0NDI4OTliIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 44,
            name: 'Life Expectancy at Birth',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMzEyODU3ZDQtMzEyMy00YjcyLWEyYjAtODhjYTlhNDA3MThhIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 45,
            name: 'Total Fertility Rate',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZDIyNzYxMzMtZjVhNC00ZTRlLTg3ZTgtYmIwNDhlNDI1YzRhIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 46,
            name: 'Maternal Mortality Rate',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMTMwMmE4NTYtMDZhOS00NzljLThiYzAtN2Y5MTM5NTNiZTA2IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 47,
            name: 'Infant Mortality Rate',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOGZmZGQwYTctMzE4OC00M2RjLWE4NTAtYmRlOTg0NWM4YTgxIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 52,
            name: 'Primary and Secondary Health Facilities',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNjA0OWE1ZWMtNjU3MC00N2YxLThiZDctMDI4MzkwMDVmNWZjIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 56,
            name: 'Patients in Mental Health Institutions',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiY2RlMGZlZmMtYjcyZC00Y2NhLWFkYzUtMjdkMmNjZjg0YzM5IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 57,
            name: 'Beds in Health Facilities',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYjg2MGQxODItNjNlNS00ZDk3LWIxZGEtYWQ1YjZlYThkMmFlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 77,
            name: 'Doctors',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZTk0OWI5YWMtNGM2Yi00ZGY4LWI0MDAtMTEwZTMwYmQ3YWY4IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 79,
            name: 'Nurses',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYzQxZjIyNTctNGY0Mi00ZDQwLWI0NzYtMGRhMzliY2JjOGNlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 81,
            name: 'Lady Health Visitors',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOTU1ZDY0NDYtZTgzOS00ZjAzLWIzNDUtYTQxMmNlNGFhNzNlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 83,
            name: 'Midwives',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMTQ3NjQxMzAtMDAwNC00MGYzLWI3OTUtOGJhYzBiODlhOGI0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 84,
            name: 'Dais',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOGQ0OTY5M2ItZGU5Ni00MTA4LTkyYTMtOGJmNzdkZjYxOWFhIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 72,
            name: 'Women who received Surgical Treatment for Fistula',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOWM4NGIwNDUtMTk1NC00NWJlLWExY2QtOWYyNDY1YWEyNWFjIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 55,
            name: 'OPD Patients visiting Health Facilities',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYmVkNWMxY2YtMDZlMy00MjgzLTllYTQtZTczNGE0MjA2MDEwIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 54,
            name: 'Mental Health Institutes',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMWFjYjNmNzEtZTRhZS00MjJmLWEyYmEtZWNjZGU2NjgzN2Q0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 406,
            name: 'Specialized Health Facilities',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOWIxNzY0YjktMWJjOC00ZGIxLThjMWEtOTViYTViMDgyYjUzIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 46,
            name: 'Maternal Mortality Rate',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZjA2ZGMxMTQtMzEwNS00MTlkLWE3YzktZTQyYmNmMTE2ZDM0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 48,
            name: 'Contraceptive Prevalence Rate',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZTllYWI1MmEtMmMyNi00YjQxLTk1MzYtZjA2NGEzOTg5MDNjIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 327,
            name: 'Patients of HIV in Treatment Centers',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMTE3ZGI1OTQtNTY1OC00ZjdmLTg4NzAtMDcxYWMxNDE0ZDljIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 78,
            name: 'Women Medical Officers (WMOs)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNzdlZjZkYzMtZWIzNi00Yjc2LWI1ODAtZjMzNDg3Nzc5OGJhIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 87,
            name: 'Lady Health Supervisors (LHS)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNzI3ZDMzZGUtOWMyZS00NGE5LTg4ZTYtMTk2NmNkZGViMDE4IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 82,
            name: 'Lady Health Visitors (LHVs)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYWZmYzUwNDQtNDI0NC00NWEyLWFjZDAtNjE4ZmU3OGMxNjVkIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 86,
            name: 'Lady Health workers (LHWs)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYjNjM2VmNjQtZDllZC00OTcyLTg2MDEtMGRlOTZiMzAyM2QxIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 370,
            name: 'Community Midwives',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMDMzM2UzZmItMTJhMC00NjMxLTkzNGEtNGNkOGRlMjk2ZjRlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 85,
            name: 'Ayas',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNGI5MGEwYjYtOWNmMC00OWUyLTk3NTYtMmMyM2E5Nzc1MWFhIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 80,
            name: 'Nurses',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiODI1NTcxMTgtOTJlNi00NjdkLWJmZDAtOGY4MjBmODEwOGY5IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 59,
            name: 'Family Planning Visits to Health Facilities',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiY2U0NDY5OTYtNmM3ZC00ZGZhLWE2ZDgtYTVjMGU1ZGQ3YmJiIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 50,
            name: 'Anaemia in women utilizing Antenatal Care',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZmE2MGYxOTQtNmYxOC00M2Q1LWI1Y2ItZDUzNWQ5NDhlNWZlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 63,
            name: 'Frequency of Antenatal care coverage (ANC) revisits',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMGM1NGFmODItYjk0Ni00MzEyLTg2NDMtZjk1ZjEwOGJjZDE2IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 51,
            name: 'Number of Low Birth Weight (LBW) Babies',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOTVmMDczZjItN2I3OS00MDg0LTg2YmUtOTkzNTJkYmNlODQ0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 61,
            name: 'Women Utilizing Antenatal Care Coverage (ANC)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiODc1NTQ4YzctZjkwZS00MGRjLTg3MzQtM2ZkZjZjNDg1YjlkIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 64,
            name: 'Live Births in Health Facilities',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMDliN2U4MDQtZjlhMC00YmM4LWE0ZWItNjM2NWM4YmVhMWJmIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 66,
            name: 'Births by Caesarean Section in Health Facilities',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZDc1ZDkzNmItZTMwMi00MmVkLWExOTItYzBmYzAzNmE4NjEzIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 65,
            name: 'Women receiving Maternal Immunization with atleast 2 doses of Tetanus Toxoid',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOWNjODExZDgtMGY1Zi00ZWQ3LTgyMDQtMDQxZTU4YTEyMjU4IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 400,
            name: 'Deaths Registered in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYmQ3YTMzZWMtMjc0ZS00ZjljLTlkOTMtYTJkYTQ2OWYwMmIzIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 3,
            name: 'Marriages Registered in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiODU0ZWVhNWMtZmEzNi00ZmUzLWJjNmUtZjU1NzIzNmNhZWMzIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 4,
            name: 'Divorces Registered in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYWE5N2NlOWItZDAwZi00OTUwLTlmYjYtMzNkZGNjYjIwOWQwIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 2,
            name: 'Births Registered in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZmExZWU4ZTctZGEwMi00MzJjLTlkZTQtZTlmMjkyNTJlZmM5IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 296,
            name: 'Population of Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMTgxMzBlZmQtYWQ4NC00OTg3LWJlYmItNTI0ZmQyZDViNmM3IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 338,
            name: 'CNICs with Disability Logo Issued in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNDMxMGU4M2MtNGE4NS00ZWFmLWI2ZTEtMWUzNWZlNGU3NGU5IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 337,
            name: 'CNICs Issued in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYWM1MzAxZmMtNjU2Yy00MTVlLWEwMjYtZjRmODk4Nzk5MjQxIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 184,
            name: 'Schemes Especially Designed for Women Farmers ',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMWU4YWUwYmEtNGY2ZC00N2EwLWFiYjEtNzJjNWRiZDEyMjNiIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 174,
            name: 'Agricultural Population ',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYTY4MDJlMGUtZDE1OC00MzVlLWJiZWMtNzFiMzZkNTUwZTYxIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 183,
            name: 'Cultivators of Rice and Cotton in Punjab ',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOTVjMTMwYTAtNmU2Ni00Yzk2LThmMWEtODc3MjgwYjAzMDQ5IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 182,
            name: 'Landholders who received Extension Services ',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYjRjM2UzMDQtYjY2ZC00MTg4LTk5NWQtYjZkMzdmOGIzN2E3IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 175,
            name: 'Landowners in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNTg5YjIzNTMtMTE2Ny00ODM3LWJmZTAtNjhjZDI1ODAyZjMwIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 376,
            name: 'Warasti Intiqal (Transfer of Inherited Propoerty)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYjJlZGZhOGEtYTZhNC00MTc1LTlkNjctMTk3ZTQ4ZGZmNmQ0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 456,
            name: 'Land Disputes in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZDJjZmYzMzktMGI0ZS00ZGE0LTlmZmUtNGU5MWNkZTI0OWVhIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 377,
            name: 'Non-Warasti Intiqal (Transfer of Non-Inherited Propoerty)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOWJhMTE4NmMtYWEzZS00ZTEyLWIyMGMtMGVjMWFjNWE3ODBiIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 185,
            name: 'Allotment of land under the Government of Punjab’s Agriculture Graduate Scheme 2010',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOTczMzAzZGMtYzg4MS00YTJlLTk5MGItZjhjMTQwYWU0ZTA2IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 177,
            name: 'Average Landholding in Punjab (square meters)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMDA3MGY0NDQtZWM4Yy00NDA4LTg3YjktYTIyZmQxM2U5MTI0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 176,
            name: 'Ratio of Land Ownership',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMTg5MjcxZjUtZGJjOS00NTVjLWJiNGItODU3N2JlMzA0NGVjIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 181,
            name: 'Purchase and Sale of Land',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiY2NlMmUyYjctMTdhYS00MjM4LThjYzEtZjVhZmYyMzE1NWU5IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 331,
            name: 'Players participating in National Sports Events (Festivals, Competitions)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMGE1Y2I0MzUtMjNmNi00YTdlLTllNTQtYmMxZDA2YTczNzA1IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 329,
            name: 'Participation in Punjab Youth Festival',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZWNkM2YyMzMtNGY3Yy00Nzg1LWJlNGEtOWMxNWM5NTU2NzdkIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 194,
            name: 'Players Playing at International Level from Punjab ',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMzc2Y2U5NjUtOWMxNS00ZWM3LWFmMWItYjBhZjBmOTljZTU1IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 196,
            name: 'Sports Facilities in Punjab ',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMWQ4YWMxNDctODkyZi00MGUzLTkzNGMtOTJmYWRjMGY4MzgwIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 330,
            name: 'Players participating in International Sports Events (Festivals, Competitions)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNDcyYTk0ODktYzJhMi00YzNiLThkZTMtNTg2NzMzNzMyZWVlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 195,
            name: 'Players Playing at National Level from Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYjRlMDMwNTQtNzM0YS00YTIzLTgyYjYtNzQ5YjhjZDQyY2I1IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 149,
            name: 'Members of  Chambers of Commerce and Industry',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZjBhNjMxNDAtODZkMS00YmVjLThhMDQtNmQzNDYxZDUwYWE1IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 41,
            name: 'Members in Bait ul Maal Council',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiY2E0NmRkZWEtMDczMi00ZjJjLTk2OTktOWUzNDRlMGUwM2YxIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 397,
            name: 'Child Working at Brick Kiln',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZWZhODkyOTQtYjllMy00MjJlLTk5ZmQtNjdlMDhmMzcyMzY1IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 346,
            name: 'Members of Zakat and Ushr Committees',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMDU3MDMxNjAtZjIzYi00ZWQ0LWE4ZTAtNjg2OTIxYTViMzNkIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 352,
            name: 'Active Loan Accounts in Bank of Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiODljZmJiMjYtMjFjYi00MDBhLTk2MDctZTQyNGQ3MjE0M2ZlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 353,
            name: 'Value of Loans issued by Bank of Punjab (Rs.)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYzIxMGE2ODktYjg5Zi00N2FiLTkyMGQtYjMwZTcyNjhlZDY3IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 159,
            name: 'Active Loan Accounts Held in Registered Micro Finance Banks ',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNmUwNDc3YjctYjRiOC00NzRmLTljNzQtOTVhZjFiNmI2NzczIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 340,
            name: 'Active Current Accounts in Bank of Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiN2VmYzRmOTQtOTQzNy00YjIzLWI4Y2QtYjA5MTcxOTZjZmJkIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 354,
            name: 'Long term loan Accounts',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNTllNTI3Y2MtM2U1MC00NDFlLTgzMjItYjBhNGI0OTgzMWE3IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 342,
            name: 'Debit Cards Issued by Bank of Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYWM1MzAxZmMtNjU2Yy00MTVlLWEwMjYtZjRmODk4Nzk5MjQxIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 355,
            name: 'Value of Long term loans',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMDI0ZDgwZjgtY2UyYS00MTYyLThlYTAtYTE4ZDIyMmEyZDlmIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 341,
            name: 'Active Deposit Accounts in Bank of Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNmU1N2M3OGYtOWVjNC00ODNhLWFkYzAtZDA4M2YwYzc2YWVlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 369,
            name: 'Beneficiaries of PSPA',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNDNkMGNkNzEtZTM5NS00OTRhLTk2M2EtMGUzN2FhNzc5NDU1IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 343,
            name: 'Beneficiaries of the Benazir Income Support Program in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNzQwOWJkN2YtMmZmMi00OGUyLWE0MzYtNzkxYTk3NjM1MWQ5IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 167,
            name: 'Social Security Payments made to Women and Men through PESSI',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiY2I4MTgyMDctYjMxMC00ZjdmLTlkZTktOGIyZGE2OTI0MzlmIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 454,
            name: 'Beneficiaries of the Benazir Income Support Program in Punjab - UCT',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiM2UwNzVlNGQtYjkwZi00ODhmLWJkMTQtZDZkN2RmZTE2ZTBhIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 455,
            name: 'Beneficiaries of the Benazir Income Support Program in Punjab - CCT',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYTI3ZTY4MTAtYzg3Yi00Njg5LTg3NDgtZTk5MWZhYWYzNmY0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 344,
            name: 'Beneficiaries of Punjab Workers Welfare Board (Scholarships)',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMWMyYzc5ZDMtY2UxZC00NzUwLWEwMTktNmNjYzg0NzE2YWYwIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 336,
            name: 'Cases of Labour Law Violations',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNmU0ODk1YTUtOWZiYS00Nzg2LThjMDktZjliMTVlMmJkZDczIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 345,
            name: 'Value of Scholarships disbursed to women and men under the Punjab workers Welfare Board ',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYWNkYWQyOTMtYjQwNy00M2I2LTg0YWItMTRiODY4YTFlODA5IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 180,
            name: 'Value of Agricultural Loans Disbursed',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMDE3ZGZiMGItNDVhNy00NDc2LWExNGQtYjBiNTc2NDI2M2I0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 179,
            name: 'Agricultural Loans given by Public Sector Banks',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiODRmYjJjMzktY2Q4Ni00NThiLTg5ZjMtZmVhYWExNDAzNmFiIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 148,
            name: 'Small Medium Enterprises (SMEs) headed by women',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYzk0ZDY3ZWQtMDgzMy00MjE2LTgwZGQtZTYyMjA3MjY4YTVlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 37,
            name: 'Members of Non-profit Organizations registered with the Social Welfare Department',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZWM3ODlmOTctODM0Ny00ZGJhLThkMDEtZWJmOTdkMzZjODFjIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 299,
            name: 'Personpower Employed In Cottage Industires',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYjIzMGRkYTgtYWY4NC00MDAxLThhOGMtNDJiMDNjNWYzNDQwIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 303,
            name: 'Proprietors in Cottage Industries',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNjVkMWMxN2MtMzczNi00NzU3LTgxNTktNWY4N2U4OGNhZjM5IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 301,
            name: 'Monthly Average Wages in Cottage Industries in Rupees',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiY2U5MWVhOWYtZDc4My00ZDgxLWI3YTItMzdlZjQ4ZDVkMjJlIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 137,
            name: 'Labour Force Participation Rate',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNWJmOTgzMjQtNzg2OS00Y2Q1LWE1ZGYtMjgwMWU5ZTdjYjY0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 140,
            name: 'Excessive Hours of Work Done by Women',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMDRmNzcyMDYtNWZmMS00MTgyLTk3MDItNmZjYmNkNDk1ZTM1IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 349,
            name: 'Percentage of Unemployed Persons',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiOGVhYmM0MjAtYWQ4Ny00ZjgyLWJhMTgtOGIxMTc2M2ZiZjRmIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 138,
            name: 'Percentage of Employed Perosns',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiYTI3ZTY4MTAtYzg3Yi00Njg5LTg3NDgtZTk5MWZhYWYzNmY0IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 305,
            name: 'Average Monthly Wages In Small Scale Industries',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiMjEyMDdhYTAtMWVjMC00M2NiLWJhMTgtMTUyMzM2M2Y1ZTQ4IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 306,
            name: 'Proprietors in Small Scale Industries',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZGUyNTM0YTYtYjYzYy00YjM2LWJhYjMtNTEwNmIxZTA0M2U2IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 300,
            name: 'Personpower Employed In Small Scale Industries',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNzg0ZGI4YTMtYzVhYi00ZGU0LWJlMzgtNDFiMzBlMDZhOWZiIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 192,
            name: 'Vehicles Owned in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNDFhNzJmMzUtN2FiMi00ZWE1LWIzYWQtNDZjN2I5YWNjNTA2IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 193,
            name: 'Driving Licenses Issued to men and women in Punjab',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiNmE0NDdlNDUtOTEwNi00ZWVjLTk1MjAtY2NkMTk0YjBlMWI1IiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        },
        {
            id: 178,
            name: 'Population involved in Livestock and Dairy Farming Activities',
            url: 'https://app.powerbi.com/view?r=eyJrIjoiZGYwODQ1OGItOTM0ZC00ZjdjLWE3NzQtNWY1YTBlY2MzZDdkIiwidCI6ImM2ZTE3NWViLTliNjktNGY3MS1hM2VlLTBhZjUwYWE4YmE4MCIsImMiOjl9&pageName=ReportSection'
        }
    ]
    for (let i = 0; i < ifr.length; i++) {
        if (ifr[i].id == pind_id) {
            url = ifr[i].url
            indi_name= ifr[i].name
        }
    }
    //alert(val)

    /* const embedContainer = document.getElementById("embed-container")
    const report = PowerBIEmbed.getElementById('embed-container')
    report.fullscreen(); */

    /* let embedConfiguration = {
        accessToken: 'H4sIAAAAAAAEACWUt66EVgAF_-W1WCKHteQCWHLOoSMsl7DkjOV_95PdT3NGo_P3j53d3zErf_78mZhDgZ6o8ooXq8c7V6LyZs_2LBRdCF-FCWblYxPL8BBQO4RPKiEpBjfK_inZGUcwWKa7ZqIu_Pk4EpIIie01dLZN3v7V7PkxS0dftsz-7OM3jaHJvFe33oepCCg6v-_DsNL60s8-e30HMlXHPtTUfkg0vEpS18TYaUaueS64K2Kv5AtuByuvoG2q6wEOGiMdj9ymeD7caY2Worx1ybLMugQFhAMgyu3zvtdEpYc81kkiX1R0UqaZojP26KG5_9jk5rmeSdfVe996LMBOe6vdl-Nx9TFLUbJA9EKo56RSxEt0lFU7h3QJZJVWnL1MwJxVKOBDyO-8g0anjVTr_N7Koj-579RgRMIW7lrhXGuYGu5ZMXnXKRx60xtrOsJMtPBYCLmUz0xijHdZfZTXBqawHzKJtL6VrUrrORJ6B3PwkgvyKr6XYl_OQPf0QVwvsB7yhpG1GWM7d7wlsTsooJZq0krDGhXrwbCX_p5NzAMPAhJBtASElNIBxfZdxVkdI--LVqoSDyXvOtUUGf3IQdN3mDienQI0UJ4KlMTsQYkeo8qpDVMt6edeWPvk86ugx1tlvlvo1TAqNtbYIFNjuPu-igUaipqEmCuYEswF_JnXY2fKQ71V0RGehHf9xJaJlnVTCc-dw4CJbn6cLb80-JCHh-sjm6bhwnnDsvy45iKCZqZ8ZqQUsX6G6nEgiOcQs2g0JKlsGRsDrkYjoqVtGjfz-shEbPAmU-g3QPIN4bjc3gnnLPz188cPv9zTNmqf-zf9L9GmoTcsshbJs0qzeiV1-p3vkXostCcDgVqVeOip2GtHNxDk-PJJO3T75NzDEo7le5qDN8lIIQEumJwYNN7DMaBIv0ml_ldvUM_cR-9R9UDbbzwx0GiLQTsIr7Ww3_kSdDLO4w7A3LJrfOEdCvrznYrOsJQFBq4TjKn_DEcu4EpwyOUpenylPTAJY3d3Pv7df7xemrI1Cs-6swsCNWIog6XitCT-NAO8gkbEw55eGz_HpPArpUYdSnCMYiVUDYZLgNxar3rE6xeGr-ToXo3iuN391haHYl-Y-KgguljYCBElnCQU1J16blPSdUK0EwJLW9XAVtBzM4vmwWoFDZyCCM5f_2m-p_qzKOGv5S43ratY9qWalDq0xv4iDut_ymvAkG378vnF9FiTHfGkx9cWhRXtVK-Dl14Eibh2ce5vqw_zxqzb-YEObO5gXl04TJTF0Zgw6A7T1eFcgUZSA9DA1vAh61l_Dpwo_DRt7zoUAGsmKE2hojQ8dzj1zE4apqRJ7o6QuYwpH8_AlObkiTLzUZn8ogM7Z9ydaqkMP_LzOwilkSM5QtqL5RNXgIiUHN9ao72wEd4uvjdK8fPbjObDKigj2IfBhG2qaOwE8LnhpSOddQy_F5osZ18l16lPeLxibgFmemniUN3EmB9n22g9JiBCgrlf-W1_21lUqbYp5ZCyKXepcH2N_HVHg8Kgxz5oOx4Y2UUy1MqQvgGOHuVkTnFZTPed322_mv_5F1bmKTjuBQAA.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImV4cCI6MTY2NTk0MDQxMH0=',
        embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=b8cc78b9-c76c-4e82-8521-19274539b765&groupId=0674cfc1-c8ca-4a47-8513-e780bee41e20&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
        id: 'b8cc78b9-c76c-4e82-8521-19274539b765',
        pageName: 'health-82-district',
        tokenType: models.TokenType.Embed,
        type: 'visual',
        visualName: 'faaccd3ea177b9515260'
    }; */
     
    // Get a reference to the HTML element that contains the embedded report.
    //let embedContainer = document.getElementById('embedContainer');
     
    // Embed the visual.
    //let visual = powerbi.embed(embedContainer, embedConfiguration);
    
  return (
    <div>
        <div class="text-center bg-light pt-5"><b>{indi_name}</b></div>
        <iframe title="health-332" width="600" height="373.5" src={url} frameBorder="1" allowFullScreen={true}></iframe>
    </div>
  )
}

export default Health
import React from 'react'
import social from '../gallery/logos/social.png'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="container-fluid footer">
        <div className="row">
            <div className="col col-md-4 mt-3" style={{textAlign:"left"}}>
                <Link to="/">Home</Link><br/>
                <Link to="about">About us</Link><br/>
                <Link to="links">Useful links</Link><br/>
                <Link to="team">Our Team</Link><br/>
                <Link to="contact">Contact us</Link>
            </div>
            <div className="col col-md-4">
                <a href="#"><img src={social} className="social"></img></a>
            </div>
            <div className="col col-md-4 mt-3">
              <div className="input-group d-flex justify-content-end">
                <div className="form-outline">
                  {/* <input type="search" id="form1" placeholder='Search' className="form-control" /> */}
                  <Link to="login" className='staff-login'>Staff Login</Link>
                </div>
                {/* <button type="button" className="btn btn-primary btn-search">
                  <i className="fas fa-search"></i>
                </button> */}
              </div>
            </div>
        </div>
        <div className="row justify-content-center copyright">
            Copyrights 2022 - 2023 | All rights reserved | PCSW - GMIS
        </div>
    </div>
  )
}

export default Footer
export const mCol = '#548FF4' //'#3366CC';
export const fCol = '#8C489F';
export const tCol = '#666666';
export const vCol = '#99CC99';

export const yearCol = '#BEB9B5';

export const subIndiCol = ['#BEB9B5', '#81A594', '#003399', '#E6E6DC', '#3366CC', '#C3C3E5', '#6699FF',
'#F1F0FF', '#74828F', '#443266', '#525564', '#C25B56', '#74828F','#525564', '#00AAA0', '#FF7A5A', '#717D8C', '#253E66', '#EF6555', '#218C8D', '#C29D4E', '#9FBF8C', '#3D4C53', '#C3C3E5', '#AA0078', 
'#C0C0C0', '#E86850', '#FFD800', '#587058', '#F69A98', '#587498', '#E86850', '#660033', '#E0E6FF', '#777B88', '#330033', '#99CCFF', '#FFCC00', '#99CC99', '#FF3333', '#005A04', 
'#CCFFBB', '#999999', '#FEF6EB', '#C25B56', '#8C489F', '#666666',];
import React, { useEffect, useState } from 'react'
import Axios from 'axios';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import {useLocation} from "react-router-dom";

function PI() {
    const { instance, accounts, inProgress } = useMsal();
    const request = {
        ...loginRequest,
        account: accounts[0]
    };
    const [token, setToken] = useState(null);
    useEffect(() => {
        instance.acquireTokenSilent(request)
            .then(response => {
                console.log(response.accessToken)
                return response.accessToken;
            }).then(response => {
                Axios({
                    method: 'post',
                    url: 'https://api.powerbi.com/v1.0/myorg/GenerateToken',
                    headers: {
                        'Authorization': 'Bearer ' + response,
                    },
                    data: {
                        "datasets": [
                          {
                            "id": "a78548b0-e9a6-4f8d-9bf6-d1bee808e65b"
                          }
                        ],
                        "reports": [
                          {
                            "id": "1faf7ffe-1d40-46c9-bf34-d6fb17ae749a"
                          }
                        ]
                      },
                }).then(response => {
                    setToken(response.data.token);
                })
            })
            .catch(error => console.log(error))
    }, [])

    const search = useLocation().search;
    const pind_id = parseInt(new URLSearchParams(search).get('id'),10); //get value of id from querystring and convert it into integer
    const basicFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "view_main",
            column: "parent_indicator_id"
        },
        operator: "In",
        values: [pind_id],
        filterType: models.FilterType.BasicFilter,
        requireSingleSelection: true
    }
    const config = {
        type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
        id: '1faf7ffe-1d40-46c9-bf34-d6fb17ae749a',
        embedUrl: "https://app.powerbi.com/reportEmbed?reportId=1faf7ffe-1d40-46c9-bf34-d6fb17ae749a&groupId=c99614c3-c912-4ae3-8bf6-22900ea55785&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d",
        accessToken: token,
        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
        permissions: models.Permissions.Read,
        filters: [basicFilter],
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true
        }
    }

    return (
        <div>
            {<PowerBIEmbed
                embedConfig={config}

                /* eventHandlers = {
                    new Map([
                        ['loaded', function () {
                            console.log('Report loaded');
                        }],
                        ['rendered', function () {
                            console.log('Report rendered');
                        }],
                        ['error', function (event) {
                            console.log(event.detail);
                        }]
                    ])
                } */

                cssClassName={
                    "report-style-class"
                }

                getEmbeddedComponent={
                    (embeddedReport) => {
                        window.report = embeddedReport;
                    }
                }
            />}
        </div>
    )
}

export default PI
import React from 'react'
import Overlay from './childrenComponents/Overlay'

function About() {
  return (
    <div className="container-fluid" style={{ minHeight: '500px', background: 'white', padding: '20px' }}>
      <div className="row">
        <div className="col">
          <h5>Introduction</h5>
          <p>
            The Punjab Commission on the Status of Women (PCSW) is a statutory, autonomous body established in February 2014 for promotion
            of women’s rights. After the devolution of women’s development to the provinces under the 18th amendment to the Constitution
            of Pakistan, PCSW was conceived as an oversight body to ensure policies and programmes of the government to promote gender equality
            in Punjab.</p>

          <p>Our major objectives are:</p>
          <ul>
            <li>Empowerment of women</li>
            <li>Elimination of all forms of discrimination against women.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default About
import { mCol, fCol, tCol, vCol, subIndiCol } from "./Colours";

function Conversion(arr) {
    arr.map(x => {
        if (x >= 1000000000) {
            x = x / 1000000000;
            x = x + 'b';
        }
        else if (x >= 1000000) {
            x = x / 1000000;
            x = x + 'm';
        }
        else if (x >= 1000) {
            x = x / 1000;
            x = x + 'k';
        }
    })
    return arr;
}

/////////////////////////////////////////////////////////////////////////////
export const dataType = {
    n: 'Number',
    p: 'Percent',
    a: 'Average',
    r: 'Ratio',
    rt: 'Rate',
}
export const xAxis = {
    d: 'District',
    pb: 'Punjab',
    pk: 'Pakistan',
    yr: 'Year'
}
export function lblMFTSub(props) {
    let mfts = ['Male', 'Female', 'Transgender',];
    for (let i = 0; i < props.length; i++) {
        if (props[i].subindicator != '') {
            mfts.push(props[i].subindicator);
            return mfts;
        }
    }
}
export const lblGP = {
    mf: ['Male', 'Female'],
    mft: ['Male', 'Female', 'Transgender'],
    mfv: ['Male', 'Female', 'Both'],
    mftv: ['Male', 'Female', 'Transgender', 'Mix data'],
}
export const lblPie = {
    mf: ['Female', 'Male'],
    mft: ['Transgender', 'Female', 'Male'],
    mfv: ['Both', 'Female', 'Male'],
    mftv: ['Mix data', 'Transgender', 'Female', 'Male'],
}
/////////////////////////////////////////////////////////////////////////////

export function DistrictList(props) {
    //console.log(props)
    let items = []; const ignoreList = ['Pakistan', 'Punjab', ''];
    for (let i = 0; i < props.length; i++) {
        items.indexOf(props[i].area_name) == -1 && !ignoreList.includes(props[i].area_name) && items.push(props[i].area_name);
    }
    //console.log(items);
    return items.sort();
}

export function YearList(props) {
    let items = [];
    for (let i = 0; i < props.length; i++) {
        items.indexOf(props[i].data_year) == -1 && items.push(props[i].data_year);
    }
    //console.log(items);
    //items.sort(function (a, b) { return a - b });
    items.sort();
    //console.log(items);
    return items;
}

export function SubindicatorList(props) {
    let items = []; const ignoreList = ['Pakistan', 'Punjab', '']
    for (let i = 0; i < props.length; i++) {
        items.indexOf(props[i].subindicator) == -1 && !ignoreList.includes(props[i].subindicator) && items.push(props[i].subindicator);
    }
    //console.log(items);
    return items.sort();
}

export function SourceList(props) {
    let items = [];
    for (let i = 0; i < props.length; i++) {
        items.indexOf(props[i].source_name) == -1 && items.push(props[i].source_name);
    }
    //console.log(items);
    return items;
}

/* export function AreaYearList(props) {
    let items = [];
    for (let i = 0; i < props.length; i++) {
        items.indexOf(props[i].area_name+'\n'+props[i].data_year) == -1 && items.push(props[i].area_name+'\n'+props[i].data_year);
    }
    //console.log(items);
    return items;
} */

/////////////////////////////////////////////////////////////////////////////

export function FDataDAllYears(props) { //read as Female data District wise All Years with no indicators
    let lbl = DistrictList(props);
    let val = 0;

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].area_name) {
                val = val + props[i].no_of_females;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function MDataDAllYears(props) { //read as Male data District wise All Years with no indicators
    let lbl = DistrictList(props);
    let val = 0;

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].area_name) {
                val = val + props[i].no_of_males;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function TDataDAllYears(props) { //read as Transgender data District wise All Years with no indicators
    let lbl = DistrictList(props);
    let val = 0;
    //let filter = YearList(props);

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].area_name) {
                val = val + props[i].no_of_trans;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function VDataDAllYears(props) { //read as Transgender data District wise All Years with no indicators
    let lbl = DistrictList(props);
    let val = 0;
    //let filter = YearList(props);

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].area_name) {
                val = val + props[i].value;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function MFDataDAllYears(props) { //read as Transgender data District wise All Years with no indicators
    return [MDataDAllYears(props), FDataDAllYears(props)]
}

export function MFTDataDAllYears(props) { //read as Transgender data District wise All Years with no indicators
    return [MDataDAllYears(props), FDataDAllYears(props), TDataDAllYears(props)]
}

export function FDataYearWise(props) { //read as Female data Year wise
    let lbl = YearList(props);
    let val = 0;
    //let filter = YearList(props);

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].data_year) {
                val = val + props[i].no_of_females;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function MDataYearWise(props) {
    let lbl = YearList(props);
    let val = 0;
    //let filter = YearList(props);

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].data_year) {
                val = val + props[i].no_of_males;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function TDataYearWise(props) {
    let lbl = YearList(props);
    let val = 0;
    //let filter = YearList(props);

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].data_year) {
                val = val + props[i].no_of_trans;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function VDataYearWise(props) {
    let lbl = YearList(props);
    let val = 0;
    //let filter = YearList(props);

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].data_year) {
                val = val + props[i].value;
                //console.log(val)
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function MFDataYearWise(props) { //read as Male Female data Year wise
    let lbl = YearList(props);
    let valF = 0; let valM = 0;
    //let filter = YearList(props);

    let mNum = new Array(lbl.length);
    let fNum = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].data_year) {
                valM = valM + props[i].no_of_males;
                valF = valF + props[i].no_of_females;
            }
        }
        mNum[j] = valM;
        fNum[j] = valF;
        valM = 0;
        valF = 0;
    }
    //console.log(items)
    return [mNum, fNum];
}

export function MFTDataYearWise(props) { //read as Male Female data Year wise
    //console.log(props)
    let lbl = YearList(props);
    let valM = 0; let valF = 0; let valT = 0;
    //let filter = YearList(props);

    let mNum = new Array(lbl.length);
    let fNum = new Array(lbl.length);
    let tNum = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].data_year) {
                valM = valM + props[i].no_of_males;
                valF = valF + props[i].no_of_females;
                valT = valT + props[i].no_of_trans;
            }
        }
        mNum[j] = valM;
        fNum[j] = valF;
        tNum[j] = valT;
        valM = 0;
        valF = 0;
        valT = 0;
    }
    //console.log(items)
    return [mNum, fNum, tNum];
}

export function MFVDataYearWise(props) { //read as Male Female Both data Year wise
    //console.log(props)
    let lbl = YearList(props);
    let valM = 0; let valF = 0; let valV = 0;
    //let filter = YearList(props);

    let mNum = new Array(lbl.length);
    let fNum = new Array(lbl.length);
    let vNum = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].data_year) {
                valM = valM + props[i].no_of_males;
                valF = valF + props[i].no_of_females;
                valV = valV + props[i].value;
            }
        }
        mNum[j] = valM;
        fNum[j] = valF;
        vNum[j] = valV;
        valM = 0;
        valF = 0;
        valV = 0;
    }
    //console.log(items)
    return [mNum, fNum, vNum];
}

export function MFTVDataYearWise(props) { //read as Male Female Trans Value(mix) data Year wise
    //console.log(props)
    let lbl = YearList(props);
    let valM = 0; let valF = 0; let valT = 0; let valV = 0;
    //let filter = YearList(props);
    //console.log(lbl)
    let mNum = new Array(lbl.length);
    let fNum = new Array(lbl.length);
    let tNum = new Array(lbl.length);
    let vNum = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].data_year) {
                valM = valM + props[i].no_of_males;
                valF = valF + props[i].no_of_females;
                valT = valT + props[i].no_of_trans;
                valV = valV + props[i].value;
            }
        }
        mNum[j] = valM;
        fNum[j] = valF;
        tNum[j] = valT;
        vNum[j] = valV;
        valM = 0;
        valF = 0;
        valT = 0;
        valV = 0;
    }
    //console.log([mNum, fNum, tNum, vNum])
    return [mNum, fNum, tNum, vNum];
}

export function MFDataSAllYears(props) { //read as Male Female data Subindicator wise
    let lbl = SubindicatorList(props);
    let valF = 0; let valM = 0;
    //let filter = YearList(props);

    let mNum = new Array(lbl.length);
    let fNum = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].subindicator) {
                valM = valM + props[i].no_of_males;
                valF = valF + props[i].no_of_females;
            }
        }
        mNum[j] = valM;
        fNum[j] = valF;
        valM = 0;
        valF = 0;
    }
    //console.log(items)
    return [mNum, fNum];
}

export function MFVDataSAllYears(props) { //read as Male Female data Subindicator wise
    let lbl = SubindicatorList(props);
    let valF = 0; let valM = 0; let valV = 0;
    //let filter = YearList(props);

    let mNum = new Array(lbl.length);
    let fNum = new Array(lbl.length);
    let vNum = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].subindicator) {
                valM = valM + props[i].no_of_males;
                valF = valF + props[i].no_of_females;
                valV = valV + props[i].value;
            }
        }
        mNum[j] = valM;
        fNum[j] = valF;
        vNum[j] = valV;
        valM = 0;
        valF = 0;
        valV = 0;
    }
    //console.log(vNum)
    return [mNum, fNum, vNum];
}

export function MFData(props) { //read as Male Female data
    let valM = 0; let valF = 0;
    //let filter = YearList(props);

    for (let i = 0; i < props.length; i++) {
        valM = valM + props[i].no_of_males;
        valF = valF + props[i].no_of_females;
    }
    //console.log(valM)
    return [valM, valF];
}

export function MFTData(props) { //read as Male Female data
    let valM = 0; let valF = 0; let valT = 0;
    //let filter = YearList(props);

    for (let i = 0; i < props.length; i++) {
        valM = valM + props[i].no_of_males;
        valF = valF + props[i].no_of_females;
        valT = valT + props[i].no_of_trans;
    }
    //console.log(valM)
    return [valM, valF, valT];
}

export function MFVData(props) { //read as Male Female data
    let valM = 0; let valF = 0; let valV = 0;
    //let filter = YearList(props);

    for (let i = 0; i < props.length; i++) {
        valM = valM + props[i].no_of_males;
        valF = valF + props[i].no_of_females;
        valV = valV + props[i].value;
    }
    //console.log(valM)
    return [valM, valF, valV];
}

export function MFTVData(props) {
    let valM = 0; let valF = 0; let valT = 0; let valV = 0;
    //let filter = YearList(props);

    for (let i = 0; i < props.length; i++) {
        valM = valM + props[i].no_of_males;
        valF = valF + props[i].no_of_females;
        valT = valT + props[i].no_of_trans;
        valV = valV + props[i].value;
    }
    //console.log(valV)
    return [valM, valF, valT, valV];
}

export function MDataSAllYears(props) { //read as Female data Subindicator wise All Years with no indicators
    let lbl = SubindicatorList(props);
    let val = 0;

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].subindicator) {
                val = val + props[i].no_of_males;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function FDataSAllYears(props) { //read as Female data Subindicator wise All Years with no indicators
    let lbl = SubindicatorList(props);
    let val = 0;

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].subindicator) {
                val = val + props[i].no_of_females;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function TDataSAllYears(props) { //read as Female data Subindicator wise All Years with no indicators
    let lbl = SubindicatorList(props);
    let val = 0;

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].subindicator) {
                val = val + props[i].no_of_trans;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function VDataSAllYears(props) { //read as Female data Subindicator wise All Years with no indicators
    let lbl = SubindicatorList(props);
    let val = 0;

    let items = new Array(lbl.length);
    for (let j = 0; j < lbl.length; j++) {
        for (let i = 0; i < props.length; i++) {
            if (lbl[j] == props[i].subindicator) {
                val = val + props[i].value;
            }
        }
        items[j] = val;
        val = 0;
    }
    //console.log(items)
    return items;
}

export function MFSimpleDataPbYearWise(props) {
    let valFPb = []; let valMPb = [];
    for (let i = 0; i < props.length; i++) {
        //lbl.push(props[i].area_name+'\n'+props[i].data_year)
        props[i].area_name == 'Punjab' && valFPb.push(props[i].no_of_females);
        props[i].area_name == 'Punjab' && valMPb.push(props[i].no_of_males);
    }
    //console.log(props)
    return [valMPb, valFPb];
}

export function MFSimpleDataPYearWise(props) {
    let valFP = []; let valMP = [];
    for (let i = 0; i < props.length; i++) {
        //lbl.push(props[i].area_name+'\n'+props[i].data_year)
        props[i].area_name == 'Pakistan' && valFP.push(props[i].no_of_females);
        props[i].area_name == 'Pakistan' && valMP.push(props[i].no_of_males);
        //console.log(valMP);
    }
    //console.log(items)
    return [valMP, valFP];
}

export function FSimpleDataPbYearWise(props) {
    let valFPb = []; let lbl = [];
    for (let i = 0; i < props.length; i++) {
        //lbl.push(props[i].area_name+'\n'+props[i].data_year)
        props[i].area_name == 'Punjab' && valFPb.push(props[i].no_of_females) && lbl.push(props[i].data_year);
    }
    //console.log(valFPb)
    return [lbl, valFPb];
}

export function FSimpleDataPYearWise(props) {
    let valFP = []; let lbl = [];
    for (let i = 0; i < props.length; i++) {
        //lbl.push(props[i].area_name+'\n'+props[i].data_year)
        props[i].area_name == 'Pakistan' && valFP.push(props[i].no_of_females) && lbl.push(props[i].data_year);
        //console.log(valMP);
    }
    //console.log(valFP)
    return [lbl, valFP];
}

export function MSimpleDataPbYearWise(props) {
    let valMPb = []; let lbl = [];
    for (let i = 0; i < props.length; i++) {
        //lbl.push(props[i].area_name+'\n'+props[i].data_year)
        props[i].area_name == 'Punjab' && valMPb.push(props[i].no_of_males) && lbl.push(props[i].data_year);
    }
    //console.log(valVPb)
    return [lbl, valMPb];
}

export function VSimpleDataPbYearWise(props) {
    let valVPb = []; let lbl = [];
    for (let i = 0; i < props.length; i++) {
        //lbl.push(props[i].area_name+'\n'+props[i].data_year)
        props[i].area_name == 'Punjab' && valVPb.push(props[i].value) && lbl.push(props[i].data_year);
    }
    //console.log(valVPb)
    return [lbl, valVPb];
}

export function VSimpleDataPYearWise(props) {
    let valVP = []; let lbl = [];
    for (let i = 0; i < props.length; i++) {
        //lbl.push(props[i].area_name+'\n'+props[i].data_year)
        props[i].area_name == 'Pakistan' && valVP.push(props[i].value) && lbl.push(props[i].data_year);
        //console.log(valMP);
    }
    //console.log(valVP)
    return [lbl, valVP];
}

export function topPieData(props) {
    let valM = 0; let valF = 0; let valT = 0; let valV = 0;
    let lblPie = []; let coloursPie = [];
    let dataPie = [];

    for (let i = 0; i < props.length; i++) {
        valM = valM + props[i].no_of_males;
        valF = valF + props[i].no_of_females;
        valT = valT + props[i].no_of_trans;
        valV = valV + props[i].value;
    }

    valM > 0 && lblPie.push('Male') && coloursPie.push(mCol) && dataPie.push(valM);
    valF > 0 && lblPie.push('Female') && coloursPie.push(fCol) && dataPie.push(valF);
    valT > 0 && lblPie.push('Transgender') && coloursPie.push(tCol) && dataPie.push(valT);
    valV > 0 && lblPie.push('Value') && coloursPie.push(vCol) && dataPie.push(valV);

    //console.log(dataPie)
    return [lblPie, coloursPie, dataPie];
}

export function subindiData(props) {    
    const len = SubindicatorList(props).length;
    const arrValM = MDataSAllYears(props); const arrValF = FDataSAllYears(props);
    const arrValT = TDataSAllYears(props); const arrValV = VDataSAllYears(props);
    let valM = 0; let valF = 0; let valT = 0; let valV = 0;
    let subindiWiseData = [];
    let lblPie = []; let coloursPie = [];
    let dataPie = [];

    for (let i = 0; i < len; i++) {
        valM = valM + arrValM[i];
        valF = valF + arrValF[i];
        valT = valT + arrValT[i];
        valV = valV + arrValV[i];
    }

    valM > 0 && subindiWiseData.push(arrValM)
    valF > 0 && subindiWiseData.push(arrValF)
    valT > 0 && subindiWiseData.push(arrValT)
    valV > 0 && subindiWiseData.push(arrValV)
    //console.log(subindiWiseData)
    return subindiWiseData;
}

export function districtData(props) {
    const barPercent = 1;
    const categoryPercent = 0.8;
    const objM = {
        label: "Male",
        backgroundColor: mCol,
        data: MDataDAllYears(props),
        barPercentage: 1,
        categoryPercentage: 0.8,
    }
    const objF = {
        label: "Female",
        backgroundColor: fCol,
        data: FDataDAllYears(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    const objT = {
        label: "Transgender",
        backgroundColor: tCol,
        data: TDataDAllYears(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    const objV = {
        label: "Value",
        backgroundColor: vCol,
        data: VDataDAllYears(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    let sumM = 0; let sumF = 0; let sumT = 0; let sumV = 0;
    for (let j = 0; j < DistrictList(props).length; j++) {
        sumM = sumM + MDataDAllYears(props)[j];
        sumF = sumF + FDataDAllYears(props)[j];
        sumT= sumT + TDataDAllYears(props)[j];
        sumV = sumV + VDataDAllYears(props)[j];
    }
    let distData = [];
    sumM > 0 && distData.push(objM);
    sumF > 0 && distData.push(objF);
    sumT > 0 && distData.push(objT);
    sumV > 0 && distData.push(objV);
    //console.log(objM.data.length);
    return distData;
}

export function yearWiseData(props) {
    const barPercent = 1;
    const categoryPercent = 0.8;
    const objM = {
        label: "Male",
        backgroundColor: mCol,
        data: MDataYearWise(props),
        barPercentage: 1,
        categoryPercentage: 0.8,
    }
    const objF = {
        label: "Female",
        backgroundColor: fCol,
        data: FDataYearWise(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    const objT = {
        label: "Transgender",
        backgroundColor: tCol,
        data: TDataYearWise(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    const objV = {
        label: "Value",
        backgroundColor: vCol,
        data: VDataYearWise(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    let sumM = 0; let sumF = 0; let sumT = 0; let sumV = 0;
    for (let j = 0; j < YearList(props).length; j++) {
        sumM = sumM + MDataYearWise(props)[j];
        sumF = sumF + FDataYearWise(props)[j];
        sumT = sumT + TDataYearWise(props)[j];
        sumV = sumV + VDataYearWise(props)[j];
    }
    let yearsData = [];
    sumM > 0 && yearsData.push(objM);
    sumF > 0 && yearsData.push(objF);
    sumT > 0 && yearsData.push(objT);
    sumV > 0 && yearsData.push(objV);
    //console.log(objM.data.length);
    return yearsData;
}

////////////////////////////////////////////// RATIO /////////////////////////////////////////

export function topPieDataRatio(props) {
    let valM = 0; let valF = 0; let valT = 0; let valV = 0;
    let lblPie = []; let coloursPie = [];
    let dataPie = [];

    for (let i = 0; i < props.length; i++) {
        valM = valM + props[i].no_of_males;
        valF = valF + props[i].no_of_females;
        valT = valT + props[i].no_of_trans;
        valV = valV + props[i].value;
    }
    valM = valM/props.length;
    valF = valF/props.length;
    valT = valT/props.length;
    valV = valV/props.length;

    valM > 0 && lblPie.push('Male') && coloursPie.push(mCol) && dataPie.push(valM);
    valF > 0 && lblPie.push('Female') && coloursPie.push(fCol) && dataPie.push(valF);
    valT > 0 && lblPie.push('Transgender') && coloursPie.push(tCol) && dataPie.push(valT);
    valV > 0 && lblPie.push('Value') && coloursPie.push(vCol) && dataPie.push(valV);

    //console.log(dataPie)
    return [lblPie, coloursPie, dataPie];
}

export function subindiDataRatio(props) {
    const len = SubindicatorList(props).length;
    let valM = MDataSAllYears(props)[0]; let valF = FDataSAllYears(props)[0];
    let valT = TDataSAllYears(props)[0]; let valV = VDataSAllYears(props)[0];
    let subindiWiseData = [];
    let lblPie = []; let coloursPie = [];
    let dataPie = [];

    valM = valM/len;
    valF = valM/len;
    valT = valM/len;
    valV = valM/len;

    valM > 0 && subindiWiseData.push(valM)
    valF > 0 && subindiWiseData.push(valF)
    valT > 0 && subindiWiseData.push(valT)
    valV > 0 && subindiWiseData.push(valV)
    //console.log(dataPie)
    return subindiWiseData
}

export function districtDataRatio(props) {
    const barPercent = 1;
    const categoryPercent = 0.8;
    const objM = {
        label: "Male",
        backgroundColor: mCol,
        data: MDataDAllYears(props),
        barPercentage: 1,
        categoryPercentage: 0.8,
    }
    const objF = {
        label: "Female",
        backgroundColor: fCol,
        data: FDataDAllYears(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    const objT = {
        label: "Transgender",
        backgroundColor: tCol,
        data: TDataDAllYears(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    const objV = {
        label: "Value",
        backgroundColor: vCol,
        data: VDataDAllYears(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    let sumM = 0; let sumF = 0; let sumT = 0; let sumV = 0;
    for (let j = 0; j < DistrictList(props).length; j++) {
        sumM = sumM + MDataDAllYears(props)[j];
        sumF = sumF + FDataDAllYears(props)[j];
        sumT= sumT + TDataDAllYears(props)[j];
        sumV = sumV + VDataDAllYears(props)[j];
    }
    let distData = [];
    sumM > 0 && distData.push(objM);
    sumF > 0 && distData.push(objF);
    sumT > 0 && distData.push(objT);
    sumV > 0 && distData.push(objV);
    //console.log(objM.data.length);
    return distData;
}

export function yearWiseDataRatio(props) {
    const barPercent = 1;
    const categoryPercent = 0.8;
    const objM = {
        label: "Male",
        backgroundColor: mCol,
        data: MDataYearWise(props),
        barPercentage: 1,
        categoryPercentage: 0.8,
    }
    const objF = {
        label: "Female",
        backgroundColor: fCol,
        data: FDataYearWise(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    const objT = {
        label: "Transgender",
        backgroundColor: tCol,
        data: TDataYearWise(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    const objV = {
        label: "Value",
        backgroundColor: vCol,
        data: VDataYearWise(props),
        barPercentage: barPercent,
        categoryPercentage: categoryPercent,
    }
    let sumM = 0; let sumF = 0; let sumT = 0; let sumV = 0;
    let len = YearList(props).length;
    for (let j = 0; j < len; j++) {
        sumM = sumM + MDataYearWise(props)[j];
        sumF = sumF + FDataYearWise(props)[j];
        sumT = sumT + TDataYearWise(props)[j];
        sumV = sumV + VDataYearWise(props)[j];
    }
    sumM = sumM/len;
    sumF = sumF/len;
    sumT = sumT/len;
    sumV = sumV/len;
    let yearsData = [];
    sumM > 0 && yearsData.push(objM);
    sumF > 0 && yearsData.push(objF);
    sumT > 0 && yearsData.push(objT);
    sumV > 0 && yearsData.push(objV);
    //console.log(objM.data.length);
    return yearsData;
}
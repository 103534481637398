import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { mCol, fCol, tCol, subIndiCol, yearCol } from '../components/Colours';

const GroupBar = (props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const data = {
    labels: props.lbl,
    datasets: props.data,
  }

  const options = {
    plugins: {
      title: {
        display: true,
        text: props.title,
        font: {
          weight: 'bold',
          size: 16,
        },
      },
    },
    scales: {
      x: {
        //stacked: true, //activate it and y to make it stacked
      },
      y: {
        //stacked: true, //activate it and x to make it stacked
        beginAtZero: true,
        title: {
          display: true,
          text: props.yAxis,
        }
      }
    }
  }

  return (
    <div className='barchart pt-3 mt-1'>
      <Bar data={data} options={options} />
    </div>
  )
}

export default GroupBar
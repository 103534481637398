import React, { useEffect, useState } from 'react';
import LogoGMIS from '../gallery/logos/8.png';
import LogoGoP from '../gallery/logos/10.png';
import LogoPCSW from '../gallery/logos/12.png';
import TextPCSW from './childrenComponents/TextPCSW';
import Axios from 'axios';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

export default function Header() {
  const [piData, setPiData] = useState(null);
  const [searchboxHtml, setSearchboxHtml] = useState('');
  useEffect(() => {
    Axios.get(`https://www.gmis.gop.pk/api/uspgetthematicsections`)
      .then(response => {
        setPiData(response.data);
      })
  }, []);

  let piList = [];
  let htmlHolder = '';
  if (piData) {
    for (let i = 0; i < piData.length; i++) {
      piList.push({piName:piData[i].parent_indicator, piId:piData[i].parent_indicator_id})
    }
  }

  const searchList = val => {
    let ss = '';
    for (let i = 0; i < val.length; i++) {
      if (i == 0) {
        ss = ss + val.charAt(i).toUpperCase();
      }
      else {
        ss = ss + val.charAt(i).toLowerCase();
      }
    }
    piList.map(x => {
      if (x.piName.startsWith(ss)) {
        htmlHolder += `<div class="search-link-container" id="pi-search-link"><a href="/report/${x.piId}" class="search-link">${x.piName}</a></div>`
      }
    })
    //console.log(htmlHolder);
    setSearchboxHtml(htmlHolder);   //lastindexof slice length
  }

  const showSearchBox = () => document.getElementById('search-result-box').style.maxHeight = '350px';
  const hideSearchBox = () => {
      (document.getElementById('search-result-box').style.maxHeight = '0px');
  }

  if (piData) {
    return (
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-lg-3 img-container">
            <Link to="/"><img src={LogoGMIS} alt="GMIS Logo" className="img-fluid logo-imgs"></img></Link>
              <a href="https://pcsw.punjab.gov.pk" target="_blank"><img src={LogoGoP} alt="GoP Logo" className="img-fluid logo-imgs"></img></a>
              <a href="https://wdd.punjab.gov.pk" target="_blank"><img src={LogoPCSW} alt="PCSW Logo" className="img-fluid logo-imgs"></img></a>
            </div>
            <div className="col-xs-12 col-lg-6">
              <div className="hd hd1">
                <TextPCSW />
              </div>
              <div className="hd hd2">
                Gender Management Information System
              </div>
            </div>
            <div className="col-xs-12 col-lg-3 col-search">
              <Link to="login" className='text-light staff-login' title="Staff login">Login</Link>
              <div className="input-group d-flex justify-content-center">
                <div className="form-outline">
                  <input type="search" id="search1" onFocus={showSearchBox} onBlur={hideSearchBox} placeholder='Search' onChange={(event) => searchList(event.target.value)} className="form-control search-box" />
                </div>
                {/* <button type="button" className="btn btn-primary btn-search">
                  <i className="fas fa-search"></i>
                </button> */}
              </div>
              <div className='search-link-box' id='search-result-box'>                
                  {parse(searchboxHtml)}
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
